import {API, graphqlOperation} from 'aws-amplify';
import {createUser, updateUser} from '../graphql/mutations';
import {getUser} from '../graphql/queries';

export async function upsertUser(userId, name, grade, buddyId, isFirstVisit) {
    try {
        // Attempt to fetch the user by ID
        const userData = await API.graphql(graphqlOperation(getUser, {id: userId}));

        if (userData.data.getUser) {
            // User exists, so update the user
            const currentVersion = userData.data.getUser._version;

            await API.graphql(graphqlOperation(updateUser, {
                input: {
                    id: userId,
                    name: name,
                    grade: grade,
                    buddyId: buddyId.toLowerCase(),
                    isFirstVisit: isFirstVisit,
                    _version: currentVersion
                }
            }));
        } else {
            // User doesn't exist, so create a new user
            await API.graphql(graphqlOperation(createUser, {
                input: {
                    id: userId,
                    name: name,
                    grade: grade,
                    buddyId: buddyId.toLowerCase(),
                    isFirstVisit: isFirstVisit
                }
            }));
        }
    } catch (error) {
        console.error('Error during upsert operation', error);
    }
}

export async function getUserById(userId) {
    try {
        const userData = await API.graphql(graphqlOperation(getUser, {id: userId}));
        return userData.data.getUser;
    } catch (error) {
        console.error("Error fetching user", error);
        throw new Error("Error fetching user");
    }
}