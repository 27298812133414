import React, {createContext, useState} from 'react';
import {Button, useAuthenticator, View} from "@aws-amplify/ui-react";
import tutorialSteps from "../../data/kidsZoneTutorialSteps.json";
import Joyride, {EVENTS} from "react-joyride";
import {getUserById, upsertUser} from "../../helpers/userInfo";
import {useNavigate} from "react-router-dom";
import {Tooltip} from "../../themed";

export const TutorialContext = createContext()
const FINAL_TUTORIAL_STEP = tutorialSteps.data.length - 1;

function TutorialWrapper({children}) {
    const {user} = useAuthenticator((context) => [context.user]);
    const navigate = useNavigate()

    const [run, setRun] = useState(false)
    const [isTourActive, setIsTourActive] = useState(false)
    const [isTourComplete, setIsTourComplete] = useState(false)
    const [stepIndex, setStepIndex] = useState(0)
    const [steps, setSteps] = useState(tutorialSteps.data);
    const [isSelectorHighlight, setIsSelectorHighlight] = useState(false)
    const [isParentRun, setIsParentRun] = useState(true)

    const [kidsRepeat, setKidsRepeat] = useState(0)

    const handleJoyrideCallback = (data) => {
        const {type} = data;

        if (type === EVENTS.STEP_AFTER && isTourActive && stepIndex === FINAL_TUTORIAL_STEP) {
            setRun(false)
            setIsTourActive(false)
            setIsTourComplete(true)
        }
    }

    const finishTutorial = () => {
        setIsTourComplete(false)
        getUserById(user.username).then(userInfo => {
            if (userInfo) {
                // on restart tutorial dont wipe out other db values
                upsertUser(user.username, userInfo.name, userInfo.grade, userInfo.buddyId, false)
            } else {
                upsertUser(user.username, '', '', '', false)
            }
        })
        navigate('/home')
    }

    return (
        <div>
            {isTourComplete && isParentRun &&
                <View style={{
                    position: 'absolute',
                    marginTop: '2rem',
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'center',
                    zIndex: 100
                }}>
                    <Button onClick={finishTutorial} style={{animation: 'pulse 1.5s infinite'}} variation={'primary'}>
                        Finish Tutorial
                    </Button>
                </View>
            }

            <TutorialContext.Provider
                value={{
                    run,
                    stepIndex,
                    steps,
                    setRun,
                    setStepIndex,
                    setSteps,
                    isTourActive,
                    setIsTourActive,
                    isSelectorHighlight,
                    setIsSelectorHighlight,
                    kidsRepeat,
                    setKidsRepeat,
                    isParentRun,
                    setIsParentRun
                }}>
                <Joyride
                    tooltipComponent={Tooltip}
                    callback={handleJoyrideCallback}
                    run={run}
                    continuous
                    spotlightClicks // Allow mouse and touch events thru the spotlight. You can click links in your app.
                    steps={tutorialSteps.data}
                    stepIndex={stepIndex}
                    styles={{
                        options: {
                            zIndex: 10000,
                        },
                    }}
                />

                {children}
            </TutorialContext.Provider>
        </div>
    );
}

export default TutorialWrapper;