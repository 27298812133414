import {
    Button,
    ButtonGroup,
    Flex, Grid,
    Heading,
    Image,
    Text,
    useAuthenticator,
    useTheme,
    View
} from "@aws-amplify/ui-react";
import {useContext, useEffect, useState} from "react";
import {useLocation, useNavigate, useParams} from "react-router-dom";
import {Analytics, DataStore} from 'aws-amplify';
import {UserActivity} from '../models';
import {BackButton} from "../themed";
import useActivities from "../hooks/useActivities";
import FeedbackModal from "./StudyBuddy/FeedbackModal";
import {TutorialContext} from "./Onboarding/TutorialWrapper";

const STATUS = {
    VIEWED: 'viewed',
    STARTED: 'started',
    COMPLETED: 'completed',
};

export default function PlayScreenActivity({isSample, audioRef}) {
    const {id} = useParams();

    const {user} = useAuthenticator((context) => [context.user]);
    const [userActivity, setUserActivity] = useState();
    const [blogContent, setBlogContent] = useState('');
    const location = useLocation();

    const activities = useActivities()
    const [activity, setActivity] = useState(null)
    const [isFeedbackModalOpen, setIsFeedbackModalOpen] = useState(false)

    useEffect(() => {
        if (activities.length !== 0) setActivity(activities.data.find(activity => activity.id === id))
    }, [activities, id])

    // Getting aspect ratio of the game. Initial value is 16:9 ratio
    const [aspectRatio, setAspectRatio] = useState(56.25)
    useEffect(() => {
        if (activity && activity.width && activity.height) {
            setAspectRatio((activity.height / activity.width) * 100)
        }
    }, [activity])

    useEffect(() => {
        if (user && !userActivity) {
            const newUserActivity = new UserActivity({
                username: user.username,
                activityID: id,
                status: STATUS.VIEWED,
            });
            DataStore.save(newUserActivity);
            setUserActivity(newUserActivity);
        }

        if (activity) {
            document.title = `${activity.name} - TeachMe TV`;
            Analytics.record({
                name: 'activity',
                attributes: {status: STATUS.VIEWED, activityID: activity.id, activityName: activity.name}
            });
            fetchBlogContent(activity.id);
        }
    }, [activity, id, user, userActivity]);

    useEffect(() => {
        if (audioRef.current) {
            audioRef.current.pause();
        }
    }, [audioRef]);

    const fetchBlogContent = async (id) => {
        setBlogContent(await (await fetch(`${process.env.PUBLIC_URL}/blog-content/${id}.html`)).text());
    }

    const [playVisible, setPlayVisible] = useState(true);
    const [startTime, setStartTime] = useState();
    const {tokens} = useTheme();
    const navigate = useNavigate();
    const [innerSize, setInnerSize] = useState({
        width: window.innerWidth,
        height: window.innerHeight,
    });

    const handleWindowSizeChange = () => setInnerSize({width: window.innerWidth, height: window.innerHeight});

    useEffect(() => {
        window.addEventListener('resize', handleWindowSizeChange);
        return () =>
            window.removeEventListener('resize', handleWindowSizeChange);
    }, []);

    const isMobile = innerSize.width <= 600 || innerSize.height < 600;

    const {setRun, setStepIndex, isTourActive} = useContext(TutorialContext)

    useEffect(() => {
        if (isTourActive) {
            setRun(true)
            setStepIndex(4)
        }
    }, [isTourActive, setRun, setStepIndex])


    const getPlatformUrl = (activity) => {
        switch (activity.platform) {
            case 'genially':
                return `https://view.genial.ly/${activity.id}`;
            case 'simmer':
                return `https://i.simmer.io/@TeachMe_TV/${activity.id}`;
            case 'vimeo':
                return `https://player.vimeo.com/video/${activity.id}?app_id=${activity.appId}&amp;h=06ba56813d&amp;referrer=https%3A%2F%2Fwww.teachmetv.co%2F`;
            case 'batterypop':
                return `https://cdn.batterypop.com/player/?distribution_id=433917e8-a206-9dec-a10f-33458886dfd3&ms_uuid=${activity.id}`;
            case 'squarespace':
                return activity.url;
            default:
                break;
        }
    }


    const changeActivityStatus = async (status) => {
        if (!userActivity)
            return;

        Analytics.record({
            name: 'activity',
            attributes: {status: status, activityID: activity.id, activityName: activity.name},
            metrics: {duration: Date.now() - startTime}
        });

        const original = await DataStore.query(UserActivity, userActivity.id);
        const newUserActivity = UserActivity.copyOf(original, updated => {
            updated.status = status;
            updated.duration = Date.now() - startTime;
        });

        await DataStore.save(newUserActivity);

        setUserActivity(newUserActivity);
    }

    return (
        <>
            {/*<View*/}
            {/*    style={{*/}
            {/*        position: 'fixed',*/}
            {/*        bottom: 0,*/}
            {/*        right: 0,*/}
            {/*        zIndex: 100,*/}
            {/*    }}*/}
            {/*>*/}
            {/*    <View style={{backgroundImage: "url(/images/thinking.png)", backgroundSize: 'contain', backgroundRepeat: 'no-repeat', position: 'absolute', bottom: '140px', right: '170px', width: '300px', height: '300px'}}>*/}
            {/*        <Text padding={tokens.space.large} marginTop={50}>Choose an activity. Bark! Bark!</Text>*/}
            {/*    </View>*/}
            {/*    <div className="dog" />*/}
            {/*</View>*/}
            <Flex direction={'column'}>
                <Grid templateColumns={'1fr 5fr 1fr'} alignItems={'center'}>
                    <View>
                        {!isSample && location.state?.fromLink !== '/studyBuddy' &&
                            <BackButton
                                onClick={async () => {
                                    if (userActivity && userActivity.status === STATUS.STARTED && (activity.length === 0 || (parseInt((Date.now() - startTime) / (1000 * 60)) > (activity.length / 2))))
                                        await changeActivityStatus(STATUS.COMPLETED);

                                    if (location.key !== 'default') {
                                        navigate(-1);
                                    } else {
                                        window.scrollTo({top: 0, left: 0, behavior: 'instant'});
                                        navigate('/');
                                    }
                                }}
                            />
                        }
                    </View>
                    <Heading level={1} id={'step-4'}>
                        {activity && activity.name}
                        {userActivity && userActivity.status === 'completed' && ' ✅'}
                    </Heading>
                    <View>
                        {!isSample && location.state?.fromLink === '/studyBuddy' && (
                            <Button
                                variation={'primary'}
                                onClick={() => setIsFeedbackModalOpen(true)}
                                style={{animation: 'pulse 1.5s infinite'}}
                            >
                                I'm Done!
                            </Button>
                        )}
                    </View>
                </Grid>

                {
                    isMobile && activity && !activity.isVertical &&
                    <Text>Turn your device sideways for a more optimal experience.</Text>
                }

                {
                    activity && activity.type !== 'music' && activity.platform !== 's3' && activity.platform !== 'blog' &&
                    <View position='relative' width={'65vw'} margin={'auto auto'}>
                        {
                            playVisible &&
                            <View width='100%' height='100%' position={'absolute'} top={0} left={0}
                                  backgroundColor={tokens.colors.overlay[50]} style={{zIndex: 100}}>
                                <Button
                                    variation='primary'
                                    onClick={async () => {
                                        setStartTime(Date.now());
                                        await changeActivityStatus(STATUS.STARTED);
                                        setPlayVisible(false);
                                    }}
                                    margin={'0'}
                                    position='absolute'
                                    top='50%'
                                    transform={'translate(-50%)'}
                                    left='50%'
                                >
                                    Play
                                </Button>
                            </View>
                        }
                        {
                            isMobile && !playVisible && !activity.hasOwnProperty('ids') &&
                            <View position={'relative'}>
                                <Button
                                    variation="primary"
                                    position={'fixed'}
                                    top={15}
                                    left={15}
                                    style={{zIndex: 101}}
                                    backgroundColor={'red'}
                                    color={'white'}
                                    onClick={() => setPlayVisible(true)}
                                >
                                    X
                                </Button>
                                <iframe
                                    title='TeachMe TV'
                                    src={`${getPlatformUrl(activity)}`}
                                    type="text/html"
                                    allowFullScreen={true}
                                    scrolling="yes"
                                    style={{
                                        position: 'fixed',
                                        top: 0,
                                        left: 0,
                                        bottom: 0,
                                        right: 0,
                                        width: '100vw',
                                        height: '100vh',
                                        border: 'none',
                                        margin: 0,
                                        padding: 0,
                                        overflow: 'hidden',
                                        zIndex: 100,
                                        backgroundColor: 'white'
                                    }}
                                />
                            </View>
                        }
                        {
                            !activity.hasOwnProperty('ids') &&
                                // Set container height with paddingTop with aspect ratio
                                <div style={{ width: '100%', paddingTop: `${aspectRatio}%`, position: 'relative' }}>
                                    <iframe
                                        title='TeachMe TV'
                                        style={{ border: 'none', position: 'absolute', top: 0, left: 0, width: '100%', height: '100%' }}
                                        src={`${getPlatformUrl(activity)}`}
                                        type="text/html"
                                        allowFullScreen={true}
                                        scrolling="yes"
                                    />
                                </div>
                        }
                        {
                            activity.hasOwnProperty('ids') &&
                                <Flex direction={isMobile ? 'column' : 'row'} justifyContent={'center'}>
                                    {activity.ids.map(id =>
                                        <iframe
                                            key={id}
                                            title='TeachMe TV'
                                            style={{
                                                border: 'none',
                                                display: 'inline-block',
                                                width: isMobile ? '100%' : '49%',
                                                height: isMobile ? '150px' : '300px'
                                            }}
                                            src={`${getPlatformUrl({id: id, appId: '122963', platform: 'vimeo'})}`}
                                            type="text/html"
                                            allowFullScreen={true}
                                            scrolling="yes"
                                        />)}
                                </Flex>
                        }
                    </View>
                }

                {
                    activity && activity.type === 'music' &&
                    <Flex direction={isMobile ? 'column' : 'row'} width={'100%'} padding={'0 1rem'}>
                        <Flex
                            direction={'column'}
                            position='relative'
                            width={isMobile ? '100%' : '50%'}
                            alignItems={'center'}
                            justifyContent={'center'}
                            margin={'auto auto'}
                        >
                            <img
                                alt={'Cover'}
                                src={`https://teachmetv.s3.amazonaws.com/images/thumbnails/${activity.thumbnail}.png`}
                                style={{
                                    display: 'block',
                                    width: 300,
                                    height: 300,
                                    margin: 'auto auto',
                                    objectFit: 'cover',
                                    backgroundPosition: '50% 50%',
                                    borderRadius: 8
                                }}
                            />
                            <audio controls style={{
                                position: 'absolute',
                                bottom: 10,
                            }}>
                                <source src={activity.url} type="audio/mpeg"/>
                                Your browser does not support the audio element.
                            </audio>
                        </Flex>

                        <embed src={activity.externalLinks[0].url} width={isMobile ? '100%' : "50%"} height="600" type="application/pdf" />
                    </Flex>
                }

                {
                    activity && activity.platform === 'blog' &&
                    <div style={{width: '90vw', maxWidth: '800px', margin: 'auto auto', textAlign: 'left'}}
                         dangerouslySetInnerHTML={{__html: blogContent}}/>
                }

                {
                    activity && activity.type === 'printable' &&
                    <div style={{width: '90vw', maxWidth: '800px', margin: 'auto auto'}}>
                        <a href={activity.url} target={'_blank'} rel="noreferrer">
                            <img
                                alt={'Cover'}
                                src={`https://teachmetv.s3.amazonaws.com/images/thumbnails/${activity.thumbnail}.png`}
                                style={{
                                    display: 'block',
                                    width: 300,
                                    height: 300,
                                    margin: 'auto auto',
                                    objectFit: 'cover',
                                    backgroundPosition: '50% 50%',
                                    borderRadius: 8
                                }}
                            />
                            <p style={{textAlign: 'center', marginTop: '1rem'}}>
                                <Button variation="primary" style={{margin: 'auto'}}>
                                    Download
                                </Button>
                            </p>
                        </a>
                    </div>
                }

                {
                    activity && activity.externalLinks.length > 0 &&
                    <ButtonGroup
                        variation="menu"
                        margin={'auto auto'}
                        direction={activity.externalLinks[0].thumbnail ? 'row' : 'column'}
                        wrap={'wrap'}
                        alignItems={activity.externalLinks[0].thumbnail ? 'flex-start' : 'center'}
                        justifyContent={'center'}
                    >
                        {
                            activity && activity.type !== 'music' && activity.externalLinks.map((link, index) => {
                                if (link.thumbnail)
                                    return <Image
                                        alt={'External link thumbnail image'}
                                        key={index}
                                        src={link.thumbnail}
                                        maxHeight={'300px'}
                                        objectFit={'contain'}
                                        objectPosition={'50% 50%'}
                                        style={{cursor: 'pointer'}}
                                        onClick={() => window.location.href = link.url}
                                    />

                                else
                                    return <Button
                                                key={index}
                                                variation="primary"
                                                backgroundColor={'#ffc022'}
                                                onClick={() => {
                                                    window.document.location.href = link.url;
                                            }}>
                                                {link.name} {activity.type === 'music' && '🎼'}
                                            </Button>
                            })
                        }
                    </ButtonGroup>
                }
                <ButtonGroup variation="menu" margin={'auto auto'} direction="row" wrap={'wrap'} alignItems={'flex-start'}
                             justifyContent={'center'}>
                    {
                        activity && activity.internalLinks && activity.internalLinks.map((link, index) => {
                            if (link.url.toLowerCase().includes('jpg') || link.url.toLowerCase().includes('png'))
                                return <Image
                                    alt={'Internal link thumbnail image'}
                                    key={index}
                                    src={link.url}
                                    maxWidth={'300px'}
                                    style={{cursor: 'pointer'}}
                                    onClick={() => window.location.href = link.url}
                                />

                            else
                                return <Button
                                            key={index}
                                            variation="primary"
                                            backgroundColor={'#ffc022'}
                                            onClick={() => {
                                                window.scrollTo({top: 0, left: 0, behavior: 'instant'});
                                                navigate(`/play/${link.url}`);
                                            }}>
                                    {link.name}
                                </Button>
                        })
                    }
                </ButtonGroup>
            </Flex>

            <FeedbackModal isOpen={isFeedbackModalOpen} setIsOpen={setIsFeedbackModalOpen} />
        </>
    )
}