import React from 'react';

const CodeBlock = ({node, inline, className, children}) => {
    const code = String(children).trim();

    if (inline) {
        return <code>{children}</code>;
    }

    const copyToClipboard = (text) => {
        const textarea = document.createElement('textarea');
        textarea.textContent = text;
        document.body.appendChild(textarea);

        textarea.select();
        document.execCommand('copy');

        document.body.removeChild(textarea);
    }


    return (
        <div style={{position: 'relative'}}>
          <pre className={className || null}>
            <code>{code}</code>
          </pre>
            <button
                style={{
                    position: 'absolute',
                    right: '-5px',
                    top: '-15px',
                    background: '#333',
                    color: '#fff',
                    border: 'none',
                    borderRadius: '3px',
                    padding: '5px 10px',
                    cursor: 'pointer'
                }}
                onClick={() => copyToClipboard(code)}
            >
                Copy Code
            </button>
        </div>
    );
};

export default CodeBlock;