// FIXME: This is an expensive operation! We should filter within the query on the backend
import query from "../query";
import {ACTIVITY_STATUS} from "../constants";

const COMPLETE_ACTIVITY_TTL_MONTH = 1;
const STARTED_ACTIVITY_TTL_DAY = 4;

export const getActivityHistory = async (user) => {
    let history = user?.username ? await query(user?.username, 'activities').catch((err) => console.error(err)) : []

    // Don't recommend activities that were already started or completed within a time range
    const now = new Date()
    const completeTTL = new Date()
    const startedTTL = new Date()
    completeTTL.setMonth(now.getMonth() - COMPLETE_ACTIVITY_TTL_MONTH)
    startedTTL.setDate(now.getDate() - STARTED_ACTIVITY_TTL_DAY);

    // Remove duplicates
    let activitiesMap = {};
    history.forEach(activity => {
        const updatedDate = new Date(activity.updatedAt);
        const existingActivity = activitiesMap[activity.activityID];

        // If there's no existing activity with this ID, or if the current activity is of higher priority, update the map
        if (!existingActivity ||
            (((activity.status === ACTIVITY_STATUS.completed && updatedDate >= completeTTL) ||
                (activity.status === ACTIVITY_STATUS.started && updatedDate >= startedTTL)) &&
            updatedDate > new Date(existingActivity.updatedAt))) {
            activitiesMap[activity.activityID] = activity
        }
    })
    history = Object.values(activitiesMap);

    history = history.filter(activity => {
        const updatedDate = new Date(activity.updatedAt)
        if (activity.status === ACTIVITY_STATUS.completed && updatedDate >= completeTTL) return false
        else if (activity.status === ACTIVITY_STATUS.started && updatedDate >= startedTTL) return false
        else return true
    })

    return history
}