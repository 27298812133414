import steps from "../../data/studyBuddySteps.json";
import {View} from "@aws-amplify/ui-react";
import DialogueBox from "../../components/Scene/DialogueBox";
import _ from 'lodash'
import {useNavigate} from "react-router-dom";
import styled from "styled-components";
import useStepChange from "../../hooks/useStepChange";
import useDialogue from "../../hooks/useDialogue";
import useRecommendations from "../../hooks/useRecommendations";
import SceneRenderer from "../../components/Scene/SceneRenderer";
import useStudyBuddyState from "../../hooks/useStudyBuddyState";
import useMic from "../../hooks/useMic";
import {StepType} from "../../constants";


const StudyBuddyContainer = styled(View)`
  background-color: white;
  height: calc(100vh - 80px);
  position: relative;

  @media only screen and (max-height: 580px) {
    height: 100vh;
  }
`

const StudyBuddy = () => {
    const navigate = useNavigate()

    const {
        user,
        videoRef,
        name,
        setName,
        selectedBuddy,
        setSelectedBuddy,
        selectedGrade,
        setSelectedGrade,
        selectedEmotion,
        setSelectedEmotion,
        selectedActivity,
        setSelectedActivity,
        isAutoPlay,
        setIsAutoPlay,
        isVoiceControlled,
        setIsVoiceControlled
    } = useStudyBuddyState()

    const [step, setStep] = useStepChange(false, '', user, name, selectedBuddy, selectedGrade, selectedEmotion, selectedActivity);
    const dialogue = useDialogue(selectedBuddy, steps, step);
    const {recommendations} = useRecommendations(user, selectedGrade, selectedEmotion, true);
    const sampleRate = useMic(isVoiceControlled, setIsVoiceControlled);

    const onClickContinue = () => {
        if (!_.isEmpty(selectedActivity)) {
            if (selectedActivity.type === 'printable' && selectedActivity.url) {
                window.location.href = selectedActivity.url;
            } else {
                navigate(`/play/${selectedActivity.id}`, {state: {fromLink: '/studyBuddy'}});
                window.scrollTo(0, 0);
            }
        } else if (selectedEmotion === 'ok') {
            navigate(`/kids-zone?grade=${selectedGrade}`);
        } else {
            setStep(Math.floor(step + 1));
        }
    }

    const _setSelectedGrade = (grade) => {
        setSelectedGrade(grade);
        localStorage.setItem('grade', grade);
    };

    const _setSelectedBuddy = (name) => {
        console.log("SETTING SELECTED BUDDY TO ", name);
        setSelectedBuddy(name);
        localStorage.setItem('selectedBuddy', name);
    }

    const onBackClick = (s) => {
        const prevStep = steps.data.filter(d => d.step === s.prevStep)[0];
        if (prevStep.type === StepType.EmotionSelection) {
            setSelectedEmotion('');
        }
        setStep(s.prevStep);
    }

    return (
        <StudyBuddyContainer>
            {steps.data.map(s => {
                if (s.step === step) {
                    return (
                        <SceneRenderer
                            key={step}
                            step={s}
                            name={name}
                            setName={setName}
                            selectedBuddy={selectedBuddy}
                            setSelectedBuddy={_setSelectedBuddy}
                            selectedGrade={selectedGrade}
                            setSelectedGrade={_setSelectedGrade}
                            setSelectedEmotion={setSelectedEmotion}
                            recommendations={recommendations}
                            selectedActivity={selectedActivity}
                            setSelectedActivity={setSelectedActivity}
                            onBackClick={() => onBackClick(s)}
                            goToNextScene={() => setStep(step + 1)}
                            videoRef={videoRef}
                            isVoiceControlled={isVoiceControlled}
                            sampleRate={sampleRate}
                        />
                    )
                }
                return null
            })}

            {dialogue !== null && (
                <DialogueBox
                    name={selectedBuddy}
                    dialogue={dialogue}
                    videoRef={videoRef}
                    isAutoPlay={isAutoPlay}
                    setIsAutoPlay={setIsAutoPlay}
                    onClick={onClickContinue}
                    isVoiceControlled={isVoiceControlled}
                    setIsVoiceControlled={setIsVoiceControlled}
                />
            )}
        </StudyBuddyContainer>
    );
};

export default StudyBuddy;
