import React, {useState} from 'react';
import {Flex, Heading, Text} from "@aws-amplify/ui-react";
import styled from 'styled-components';
import { useLocation } from 'react-router-dom';
import { Dialog, DialogContent, DialogTitle, IconButton } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import { sendGA4Event } from '../../helpers/ga4';

export default function RestaurantGridItem(props) {
    const { activity, baseColor, description, warning, image, iframeWidth, iframeHeight } = props;
    const [open, setOpen] = useState(false);
    const [startTime, setStartTime] = useState();
    const location = useLocation();

    // Calculate a slightly darker shade of the baseColor
    const gradientEndColor = darkenColor(baseColor, 10);

    const AnimatedButton = styled.button`
    position: relative;
    overflow: hidden;
    border: 1px solid #ccc;
    height: 33vh;
    cursor: pointer;

    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background: linear-gradient(
        to bottom,
        ${baseColor} 0%,
        ${gradientEndColor} 100%
      );
      transition: opacity 0.3s ease;
    }

    &:hover::before {
      opacity: 1;
    }
  `;

    const HeadingWrapper = styled.div`
    position: relative;
    z-index: 1;

      > * {
        margin-bottom: 0.3em; /* Adjust the desired amount of vertical spacing */
      }
  `;

    const DescriptionText = styled(Text)`
      color: white;
      text-shadow: none;
  `;

    const getGradeText = () => {
        const {grades} = activity

        if (grades.length === 1)
            return `Grade: ${grades[0]}`

        return `Grades: ${grades[0]} - ${grades[grades.length - 1]}`
    }

    if (!activity) return <></>;

    const handleOpen = () => {
        sendGA4Event('Open Activity', {
            source: location.search,
            activityid: activity.id,
            activityName: activity.name,
        });
        setStartTime(Date.now());
        setOpen(true);
    };

    const handleClose = () => {
        sendGA4Event('Close Activity', {
            source: location.search,
            activityid: activity.id,
            activityName: activity.name,
            duration: Date.now() - startTime
        });
        setOpen(false);
    };

    const getPlatformUrl = (activity) => {
        switch (activity.platform) {
            case 'genially':
                return `https://view.genial.ly/${activity.id}`;
            case 'simmer':
                return `https://i.simmer.io/@TeachMe_TV/${activity.id}`;
            case 'vimeo':
                return `https://player.vimeo.com/video/${activity.id}?app_id=${activity.appId}&amp;h=06ba56813d&amp;referrer=https%3A%2F%2Fwww.teachmetv.co%2F`;
            case 'batterypop':
                return `https://cdn.batterypop.com/player/?distribution_id=433917e8-a206-9dec-a10f-33458886dfd3&ms_uuid=${activity.id}`;
            case 'squarespace':
                return activity.url;
            case 's3':
                return activity.url;
            default:
                break;
        }
    }

    return (
        <>
            <AnimatedButton onClick={handleOpen}>
                <HeadingWrapper>
                    <Flex
                        direction={'row'}
                        justifyContent={'center'}
                        alignItems={'center'}
                    >
                        {image && window.innerWidth > 768 &&
                            <img src={image} alt={'Thumbnail'} width={200} />
                        }
                        <div>
                            <Heading level={3} color={'white'}>
                                {activity.name}
                            </Heading>
                            <Heading level={4} color={'white'}>
                                {getGradeText()}
                            </Heading>
                            <DescriptionText>{description}</DescriptionText>
                        </div>
                    </Flex>
                </HeadingWrapper>
            </AnimatedButton>
            <Dialog open={open} onClose={handleClose} fullScreen>
                <DialogTitle>
                    <IconButton
                        aria-label="close"
                        onClick={handleClose}
                        sx={{
                            position: 'absolute',
                            right: 8,
                            top: 8,
                            backgroundColor: 'red',
                            '&:hover': {
                                backgroundColor: 'darkred',
                            },
                        }}
                    >
                        <CloseIcon sx={{ color: 'white', fontWeight: 'bolder' }} />
                    </IconButton>
                    {activity.name}
                    {warning &&
                        <Text
                            fontSize={16}
                            color={'#333333'}
                        >
                            {warning}
                        </Text>
                    }
                </DialogTitle>
                <DialogContent
                    style={{
                        // position: 'relative',
                        // width: '100%',
                        // paddingBottom: 'calc(1812 / 3221 * 100%)' /* 16:9 aspect ratio */
                    }}
                >
                    <Flex
                        justifyContent={'center'}
                        alignItems={'center'}
                        height={'100%'}
                    >
                        <iframe
                            id={'iframe'}
                            title='TeachMe TV'
                            style={{
                                border: 'none',
                                width: iframeWidth ? iframeWidth : '100%',
                                height: iframeHeight ? iframeHeight : '100%',
                            }}
                            src={`${getPlatformUrl(activity)}`}
                            type="text/html"
                            allowFullScreen={true}
                            scrolling="yes"
                        />
                    </Flex>
                </DialogContent>
            </Dialog>
        </>
    );
}

// Function to darken a color by a given percentage
function darkenColor(color, percent) {
    const hex = color.slice(1);
    const num = parseInt(hex, 16);
    const amt = Math.round(2.55 * percent);
    const R = (num >> 16) - amt;
    const B = ((num >> 8) & 0x00FF) - amt;
    const G = (num & 0x0000FF) - amt;
    const newHex = (0x1000000 + (R < 255 ? R < 1 ? 0 : R : 255) * 0x10000 + (B < 255 ? B < 1 ? 0 : B : 255) * 0x100 + (G < 255 ? G < 1 ? 0 : G : 255)).toString(16).slice(1);
    return `#${newHex}`;
}
