import { Button, Divider, Flex, Heading, Image, View } from "@aws-amplify/ui-react";
import { MdOutlineAdd } from "react-icons/md";
import getActivities from "../../../helpers/getActivities";
import { DataGridPro, GridColDef, GridRenderCellParams, GridToolbarColumnsButton, GridToolbarContainer, GridToolbarDensitySelector, GridToolbarFilterButton, GridToolbarQuickFilter } from "@mui/x-data-grid-pro";

import { LicenseInfo } from "@mui/x-license-pro";
import React, { useCallback, useEffect, useState } from "react";
import axios from "axios";
import MultiSelect from "./MultiSelect";
import LinkInputCell from "./LinkInputCell";
import LinkDisplay from "./LinkDisplay";
import AdminAcitivityModal from "./AdminAcitivityModal";
import { getKeywords } from "./getKeywords";
import { MultiSelectCellInternalLinks } from "./MultiSelectInternalLinks";
import { getInternalLinks } from "./getInternalLinks";
import { AiOutlineLoading3Quarters } from "react-icons/ai";
import { deleteThumbnail } from "./thumbnailAPI";

LicenseInfo.setLicenseKey("308423d9eb32896eac8a5dd10bf0226fT1JERVI6NDAxMDUsRVhQSVJZPTE2Nzk0ODI1NDIwMDAsS0VZVkVSU0lPTj0x");

export default function AdminEditor(props) {
    const environmentURL = document.location.href;

    const [allowAccess, setAllowAccess] = useState(false);
    const [activities, setActivities] = useState([]);
    const [keywordsOptions, setKeywordsOptions] = useState([]);
    const [internalLinksOptions, setInternalLinksOptions] = useState([]);
    const [isAddModalOpen, setIsAddModalOpen] = useState(false);
    const [isDeployingToBeta, setIsDeployingToBeta] = useState(false);
    const [isDeployingToLive, setIsDeployingToLive] = useState(false);
    const [isEditModalOpen, setIsEditModalOpen] = useState(false);
    const [editRow, setEditRow] = useState(null);

    const handleCellDoubleClick = (params) => {
        if (params.field === "name") {
            setEditRow(params.row);
            setIsEditModalOpen(true);
        }
    };

    const callGetActivities = async () => (await getActivities()).data;

    useEffect(() => {
        const handleBeforeUnload = (event) => {
            const shouldAlert = sessionStorage.getItem("isAdminSaved");
            if (shouldAlert === "false") {
                event.preventDefault();
                event.returnValue = ""; // For older browsers
                alert("There are unsaved changes! Make sure to copy or deploy.");
            }
        };

        window.addEventListener("beforeunload", handleBeforeUnload);
        return () => window.removeEventListener("beforeunload", handleBeforeUnload);
    }, []);

    useEffect(() => {
        const searchParams = new URLSearchParams(window.location.search);
        setAllowAccess(searchParams.get("password") === process.env["REACT_APP_ADMIN_PASSWORD"]);

        // Why did we store in session storage?
        // If another user uses the editor, the current user wouldn't be able to see their changes.
        // Am I missing something here?
        // const storedActivities = sessionStorage.getItem('activities')
        // if (storedActivities) {
        //     const parsedData = JSON.parse(storedActivities)
        //     const storedTimestamp = parsedData.timestamp
        //     const currentTime = Date.now()
        //     if (currentTime - storedTimestamp <= timeToLive) {
        //         setActivities(parsedData.data)
        //         return
        //     }
        // }

        callGetActivities().then((data) => {
            setActivities(data);
            // sessionStorage.setItem('activities', JSON.stringify({
            //     timestamp: Date.now(),
            //     data: data
            // }))
        });
    }, []);

    const getKeywordsCallback = useCallback(() => getKeywords(activities), [activities]);
    const getInternalLinksCallback = useCallback(() => getInternalLinks(activities), [activities]);

    useEffect(() => {
        setKeywordsOptions(getKeywordsCallback());
    }, [getKeywordsCallback]);

    useEffect(() => {
        setInternalLinksOptions(getInternalLinksCallback());
    }, [getInternalLinksCallback]);

    const deployChanges = (isPushToLive = false) => {
        if (isPushToLive) {
            if (window.confirm("Push current version to live site?")) {
                setIsDeployingToLive(true);
            } else {
                return;
            }
        } else {
            setIsDeployingToBeta(true);
        }

        const endpoint = !isPushToLive ? process.env["REACT_APP_AWS_API_ENDPOINT_DEV"] : process.env["REACT_APP_AWS_API_ENDPOINT_PROD"];

        sessionStorage.removeItem("activities");
        axios
            .post(`${endpoint}/s3`, {
                prefix: "data",
                file: "activities.json",
                data: {
                    version: "1.0.0",
                    data: activities,
                },
            })
            .then((_) => {
                if (isPushToLive) {
                    setIsDeployingToLive(false);
                    alert("Successfully pushed to live site!");
                } else {
                    setIsDeployingToBeta(false);
                    alert("Successfully saved!");
                    sessionStorage.removeItem("isAdminSaved");
                }
                sessionStorage.removeItem("activities");
            })
            .catch((err) => {
                alert("Save was unsuccessful. Error reason: " + err.toJSON());
            });
    };

    const handleEditCellChange = useCallback(
        (params) => {
            const { id, field, value } = params;

            // Prevent from running this when user clicks outside of the cell
            // Only run as specified by LinkInputCell.jsx. Otherwise previous state will be saved
            if ((field === "internalLinks" || field === "externalLinks") && !params.runFlag) return;

            let newValue = value;
            if (field === "priority") {
                newValue = parseInt(value);
                if (isNaN(newValue)) {
                    // If the new value is not a valid number, set it to the current value
                    newValue = activities.find((row) => row.id === id).priority;
                } else {
                    const minValue = 1;
                    const maxValue = 5;
                    newValue = Math.max(minValue, Math.min(newValue, maxValue));
                }
            }

            const updatedRows = activities.map((row) => {
                if (row.id === id) {
                    return {
                        ...row,
                        [field]: newValue,
                    };
                }

                return row;
            });

            setActivities(updatedRows);
            sessionStorage.setItem("isAdminSaved", false);
        },
        [activities]
    );

    function CustomToolbar() {
        return (
            <GridToolbarContainer>
                <Flex style={{ width: "100%" }} justifyContent={"space-between"} alignItems={"center"}>
                    <Flex gap={0}>
                        <GridToolbarColumnsButton />
                        <GridToolbarDensitySelector />
                        <GridToolbarFilterButton />
                    </Flex>

                    <GridToolbarQuickFilter />

                    <Flex>
                        <Button variation="primary" onClick={() => setIsAddModalOpen(true)}>
                            <MdOutlineAdd />
                        </Button>
                        {environmentURL.includes("localhost") ? (
                            <Button
                                onClick={async () => {
                                    await navigator.clipboard.writeText(
                                        JSON.stringify({
                                            version: "1.0.0",
                                            data: activities,
                                        })
                                    );
                                    alert("Successfully copied!");
                                }}
                            >
                                Copy JSON to clipboard
                            </Button>
                        ) : (
                            <>
                                <Button onClick={() => deployChanges(false)} style={{ display: "flex", gap: "0.5rem" }}>
                                    Save
                                    {isDeployingToBeta && <AiOutlineLoading3Quarters className={"loading"} />}
                                </Button>
                                <Button onClick={() => deployChanges(true)} style={{ display: "flex", gap: "0.5rem" }}>
                                    Push to Live
                                    {isDeployingToLive && <AiOutlineLoading3Quarters className={"loading"} />}
                                </Button>
                            </>
                        )}
                    </Flex>
                </Flex>
            </GridToolbarContainer>
        );
    }

    const handleRowOrderChange = useCallback(
        ({ oldIndex, targetIndex }) => {
            const newRows = [...activities];
            const row = newRows.splice(oldIndex, 1)[0];
            newRows.splice(targetIndex, 0, row);
            setActivities(newRows);
            sessionStorage.setItem("isAdminSaved", false);
        },
        [activities]
    );

    const handleDelete = useCallback(
        (id) => {
            const confirmDelete = window.confirm("Are you sure you want to delete this activity?");
            if (confirmDelete) {
                const activityToDelete = activities.find((activity) => activity.id === id);
                deleteThumbnail(`${activityToDelete.thumbnail}.png`)
                    .then(() => {
                        const updatedActivities = activities.filter((activity) => activity.id !== id);
                        setActivities(updatedActivities);
                        sessionStorage.setItem("isAdminSaved", false);
                    })
                    .catch((err) => {
                        alert("Deletion failed. Please try again. Error message: " + err);
                    });
            }
        },
        [activities, setActivities]
    );

    const columns: GridColDef[] = [
        {
            field: "thumbnail",
            headerName: "Thumbnail",
            width: 200,
            editable: true,
            renderCell: (params: GridRenderCellParams<string[]>) => <Image alt={"thumbnail"} src={`https://teachmetv.s3.amazonaws.com/images/thumbnails/${params.value}.png?time=${Date.now()}`} width={200} objectFit={"contain"} />,
        },
        { field: "name", headerName: "Name", width: 250, editable: true },
        { field: "priority", headerName: "Priority", type: "number", width: 80, editable: true },
        {
            field: "type",
            headerName: "Type",
            width: 100,
            editable: true,
            type: "singleSelect",
            valueOptions: [
                { value: "activity", label: "Activity" },
                { value: "game", label: "Game" },
                { value: "music", label: "Music" },
                { value: "printable", label: "Printable" },
                { value: "video", label: "Video" },
            ],
        },
        {
            field: "grades",
            headerName: "Grades",
            width: 220,
            editable: true,
            renderCell: (params) => <MultiSelect valueOptions={["K", "1", "2", "3", "4", "5", "6"]} value={params.value} onValueChange={(newValue) => handleEditCellChange({ ...params, value: newValue }, { target: { value: newValue } })} />,
        },
        {
            field: "platform",
            headerName: "Platform",
            width: 200,
            editable: true,
            type: "singleSelect",
            valueOptions: [
                { value: "batterypop", label: "Battery Pop" },
                { value: "genially", label: "Genially" },
                { value: "music", label: "Music" },
                { value: "simmer", label: "Simmer" },
                { value: "s3", label: "S3" },
                { value: "Squarespace", label: "Squarespace" },
                { value: "vimeo", label: "Vimeo" },
            ],
        },
        {
            field: "subjects",
            headerName: "Subjects",
            width: 350,
            editable: true,
            renderCell: (params) => (
                <MultiSelect
                    valueOptions={["Math", "Language Arts", "Science", "Music", "Art", "Exercise", "Kids Teaching Kids"]}
                    value={params.value}
                    onValueChange={(newValue) => handleEditCellChange({ ...params, value: newValue }, { target: { value: newValue } })}
                />
            ),
        },
        {
            field: "keywords",
            headerName: "Keywords",
            width: 450,
            editable: true,
            renderCell: (params) => (
                <MultiSelect
                    isSearchable
                    isAppendable
                    valueOptions={keywordsOptions}
                    setValueOptions={setKeywordsOptions}
                    value={params.value}
                    onValueChange={(newValue) => handleEditCellChange({ ...params, value: newValue }, { target: { value: newValue } })}
                />
            ),
        },
        { field: "description", headerName: "Description", width: 400, editable: true },
        {
            field: "externalLinks",
            headerName: "externalLinks",
            width: 500,
            editable: true,
            renderCell: (params: GridRenderCellParams<string[]>) => {
                return params.value && <LinkDisplay key={params.id} value={params.value} />;
            },
            renderEditCell: (params) => (
                <LinkInputCell
                    {...params}
                    onValueChange={(newValue, runFlag) => {
                        handleEditCellChange(
                            {
                                ...params,
                                value: newValue,
                                runFlag: runFlag,
                            },
                            { target: { value: newValue } }
                        );
                    }}
                />
            ),
        },
        {
            field: "internalLinks",
            headerName: "internalLinks",
            width: 500,
            editable: true,
            renderCell: (params: GridRenderCellParams<string[]>) => {
                return params.value && <LinkDisplay value={params.value} />;
            },
            renderEditCell: (params) => (
                <MultiSelectCellInternalLinks
                    {...params}
                    onValueChange={(newValue, runFlag) => {
                        handleEditCellChange(
                            {
                                ...params,
                                value: newValue,
                                runFlag: runFlag,
                            },
                            { target: { value: newValue } }
                        );
                    }}
                    valueOptions={internalLinksOptions}
                    isSearchable
                />
            ),
        },
        {
            field: "delete",
            headerName: "Delete",
            width: 150,
            renderCell: (params) => (
                <Button variant="outlined" color="secondary" onClick={() => handleDelete(params.row.id)}>
                    Delete
                </Button>
            ),
        },
    ];

    if (!allowAccess) return <Heading level={1}>Not allowed</Heading>;

    return (
        <>
            <Flex direction={"column"} alignItems={"center"} justifyContent={"center"} position={"relative"} height={800}>
                <Heading level={1}>Editor</Heading>
                <Divider />
                <View style={{ flexGrow: 1 }} width={"100vw"}>
                    <DataGridPro
                        columns={columns}
                        rows={activities}
                        getRowHeight={() => "auto"}
                        initialState={{ pinnedColumns: { left: ["name"], right: ["actions"] } }}
                        onCellEditCommit={handleEditCellChange}
                        onCellDoubleClick={handleCellDoubleClick}
                        rowReordering
                        onRowOrderChange={handleRowOrderChange}
                        components={{ Toolbar: CustomToolbar }}
                    />
                </View>
                <Divider />
            </Flex>

            <AdminAcitivityModal isOpen={isAddModalOpen} setIsOpen={setIsAddModalOpen} activities={activities} setActivities={setActivities} mode={"add"} />

            <AdminAcitivityModal isOpen={isEditModalOpen} setIsOpen={setIsEditModalOpen} activities={activities} setActivities={setActivities} mode="edit" activityToEdit={editRow} />
        </>
    );
}
