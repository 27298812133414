import {
    Button, Flex,
    Heading,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Text,
    useTheme,
    View
} from "@aws-amplify/ui-react";
import {useNavigate} from "react-router-dom";
import activities from "../data/activities.json";
import {AiOutlineLoading3Quarters} from "react-icons/ai";

function convertArrayToObject(array, key) {
    const initialValue = {};
    return array.reduce((obj, item) => {
      return {
        ...obj,
        [item[key]]: item,
      };
    }, initialValue);
  };

export default function ChildReportTable({userData}) {
    const {tokens} = useTheme();
    const navigate = useNavigate();

    const activitiesMap = convertArrayToObject(activities.data, 'id');

    const formatDate = (date) =>
        `${date.toLocaleString('default', {month: 'short'})} ${date.getDate()}, ${date.getFullYear()}`

    const diffDurationInMinutes = (date1, date2) => {
        if (!date1 || !date2)
            return 0;

        return Math.ceil((((new Date(date1) - new Date(date2)) % 86400000) % 3600000) / 60000);
    }

    return !userData ? (
        <Flex direction={'row'} justifyContent={'center'} alignItems={'center'} padding={tokens.space.large}>
            <AiOutlineLoading3Quarters className={'loading'} />
            <Text>Fetching account information...</Text>
        </Flex>
    ) : (
        <View padding={tokens.space.large} width={'100%'}>
            <Heading level={3}>Child Report</Heading>
            {/* TODO: <Flex
                direction={'row'}
                width={'500px'}
                margin={'auto auto'}
                justifyContent={'center'}
                alignItems={'center'}
                paddingBottom={tokens.space.large}
            >
                <Text>Date Range: </Text>
                <DatePicker selected={fromDate} onChange={(date) => setFromDate(date)} />
                <Text> to </Text>
                <DatePicker selected={toDate} onChange={(date) => setToDate(date)} />
            </Flex> */}
            <Heading level={4}>History</Heading>
            <Table variation='striped' highlightOnHover={true}>
                <TableHead>
                    <TableRow backgroundColor={'#00acee'}>
                        <TableCell as='th' color={'white'}>Date</TableCell>
                        <TableCell as='th' color={'white'}>Activity</TableCell>
                        <TableCell as='th' color={'white'}>Type</TableCell>
                        <TableCell as='th' color={'white'}>Status</TableCell>
                        <TableCell as='th' color={'white'}>Duration in minutes</TableCell>
                        <TableCell as='th' color={'white'}>Grades</TableCell>
                        <TableCell as='th' color={'white'}>Subjects</TableCell>
                        <TableCell as='th' color={'white'}>Keywords</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {
                        userData && userData.activities &&
                        userData.activities
                            .sort((a, b) => new Date(b.updatedAt) - new Date(a.updatedAt))
                            // TODO: .filter(userActivity => {
                            //     const updatedAtDate = new Date(userActivity.updatedAt);
                            //     const from = new Date(`${fromDate.toLocaleDateString()}T00:00:00Z`);
                            //     const to = new Date(`${toDate.toLocaleDateString()}T23:59:59Z`);

                            //     return updatedAtDate >= from && updatedAtDate <= to;
                            // })
                            .map((userActivity, index) => {
                                const updatedAtDate = new Date(userActivity.updatedAt);
                                const activityDetails = activitiesMap[userActivity.activityID];
                                if (!activityDetails)
                                    return '';

                                return <TableRow key={index}>
                                    <TableCell>{formatDate(updatedAtDate)}</TableCell>
                                    <TableCell textAlign={'left'}>
                                        <Button variation='link'
                                                onClick={() => navigate(`/play/${activityDetails.id}`)}>
                                            {activityDetails.name}
                                        </Button>
                                    </TableCell>
                                    <TableCell>{activityDetails.type.toUpperCase()}</TableCell>
                                    <TableCell>{userActivity.status.toUpperCase()}</TableCell>
                                    <TableCell>
                                        {diffDurationInMinutes(userActivity.updatedAt, userActivity.createdAt)}
                                    </TableCell>
                                    <TableCell>{activityDetails.grades?.join(', ')}</TableCell>
                                    <TableCell>{activityDetails.subjects?.join(', ')}</TableCell>
                                    <TableCell>{activityDetails.keywords ? activityDetails.keywords.join(', ') : ''}</TableCell>
                                </TableRow>
                            })
                    }
                    <TableRow>
                        <TableCell colSpan={8} align={'right'} fontWeight={'bold'}>
                            Summary of activity:&nbsp;
                            {
                                userData && userData.activities ?
                                    userData.activities
                                        // TODO: .filter(userActivity => {
                                        //     const updatedAtDate = new Date(userActivity.updatedAt);
                                        //     const from = new Date(`${fromDate.toLocaleDateString()}T00:00:00Z`);
                                        //     const to = new Date(`${toDate.toLocaleDateString()}T23:59:59Z`);

                                        //     return updatedAtDate >= from && updatedAtDate <= to;
                                        // })
                                        .reduce((accumulator, userActivity) => {
                                            return accumulator + diffDurationInMinutes(userActivity.updatedAt, userActivity.createdAt)
                                        }, 0) + ' minutes'
                                    : '0 minutes'
                            }
                        </TableCell>
                    </TableRow>
                </TableBody>
            </Table>
        </View>
    )
}
