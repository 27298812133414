import React, {useCallback, useEffect, useRef} from 'react';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import './BuddyCarousel.css'

const BuddyCarousel = ({ characters, selectedBuddy, setSelectedBuddy, videoRefs }) => {
    const prevSelectedChar = useRef(selectedBuddy);

    useEffect(() => {
        if (prevSelectedChar.current !== selectedBuddy) {
            const prevIndex = characters.data.findIndex(char => char.name === prevSelectedChar.current);
            if (videoRefs.current[prevIndex]) {
                videoRefs.current[prevIndex].pause();
                videoRefs.current[prevIndex].currentTime = 0;
            }
        }
        prevSelectedChar.current = selectedBuddy;
    }, [selectedBuddy, characters, videoRefs]);

    const responsive = {
        superLargeDesktop: {
            breakpoint: { max: 4000, min: 3000 },
            items: 4,
        },
        desktop: {
            breakpoint: { max: 3000, min: 1024 },
            items: 4,
        },
        tablet: {
            breakpoint: { max: 1024, min: 464 },
            items: 4,
        },
        mobile: {
            breakpoint: { max: 464, min: 0 },
            items: 1,
        },
    };

    // TODO: clean this up... Can we just set onClick -> onSelect? Do we even need it, or does the carousel handle onSelect?
    const handleCharacterClick = useCallback((characterName) => {
        setSelectedBuddy(characterName);
    }, [setSelectedBuddy]);

    return (
        <Carousel
            responsive={responsive}
            centerMode
            draggable
            focusOnSelect={false}
            keyBoardControl
            minimumTouchDrag={80}
            renderButtonGroupOutside={false}
            renderDotsOutside={false}
            showDots={false}
            sliderClass=""
            slidesToSlide={1}
            swipeable
        >
            {characters.data.map(character => (
                <img
                    onClick={() => handleCharacterClick(character.name)}
                    key={character.name}
                    src={character.image}
                    alt={character.name}
                    style={{
                        cursor: 'pointer',
                        border: selectedBuddy === character.name ? '2px solid #F6931E' : 'none',
                        height: '100%',
                        aspectRatio: 1,
                        filter: selectedBuddy !== character.name ? 'blur(1px)' : ''
                    }}
                />
            ))}
        </Carousel>
    );
};

export default BuddyCarousel;
