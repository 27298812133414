import { Flex, Image, Text, TextField, View } from "@aws-amplify/ui-react";
import { AudioText, MuliHeading } from "../themed";
import { useContext, useEffect, useState } from "react";
import { AudioContext } from "../App";

export default function SearchBar(props) {
  const {
    isMobile,
    activities,
    setSearchTerm,
    upperLabel = "Search here:",
    playUpperLabelAudio = true,
    playLowerLabelAudio = true,
    isStars = true,
    ...domProps
  } = props;

  const [error, setError] = useState("");
  const { playVO } = useContext(AudioContext);

  useEffect(() => {
    const hasResults = activities.length > 0;
    setError(hasResults ? "" : "No results. Try another search term.");
  }, [activities]);

  const trimString = (str) => {
    return str.replace(/^\s+|\s+$/g, "");
  };

  return (
    <Flex direction={"column"} maxWidth={"50vw"} {...domProps}>
      <MuliHeading level={2}>
        {playUpperLabelAudio && (
          <AudioText sound={"OrSearchSomethingElse"}>
              {upperLabel}
          </AudioText>
        )}
        {!playUpperLabelAudio && <Text>{upperLabel}</Text>}
      </MuliHeading>
      <View
        style={{
          backgroundImage: 'url("/images/kids-zone/yellow-tape.svg")',
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          height: isMobile ? 75 : 100,
          position: "relative",
          paddingRight: isMobile ? 0 : 60,
        }}
      >
        {isStars && (
          <Image
            alt={"stars"}
            src={"/images/kids-zone/stars.png"}
            position={"absolute"}
            left={-15}
            width={100}
          />
        )}
        <TextField
          label={"Keyword"}
          labelHidden={true}
          placeholder={
            "For example: video, addition, songs, games, interactive, ..."
          }
          marginTop={isMobile ? 24 : 35}
          marginLeft={isMobile ? 20 : 105}
          textAlign={"left"}
          width={"80%"}
          onMouseEnter={() =>
            playVO("SearchBar").then(
              () => playLowerLabelAudio && playVO("SearchHelp")
            )
          }
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              setSearchTerm(trimString(e.target.value.toLowerCase()));
            }
          }}
        />
      </View>

      {error && <Text color={"red"}>{error}</Text>}
    </Flex>
  );
}
