import React, { useEffect, useState } from "react";
import { Flex } from "@aws-amplify/ui-react";
import { useNavigate } from "react-router-dom";
import { containsYesNoQuestion } from "../../helpers/nlpHelper";

const ActionBar = ({
  isThinking,
  isViewingActivity,
  messages,
  handleSendMessage,
  handleAssistantResponse,
  selectedGrade,
  isPlayground
}) => {
  const navigate = useNavigate();
  const [isYesNoQuestion, setIsYesNoQuestion] = useState(false);

  useEffect(() => {
    const lastMessage = messages[messages.length - 1];
    if (!lastMessage || lastMessage.text.trim() === "") {
      setIsYesNoQuestion(false);
      return;
    }

    const sentences = lastMessage.text.split(". ");
    if (sentences.length < 1) {
      setIsYesNoQuestion(false);
      return;
    }

    const result = containsYesNoQuestion(lastMessage.text.trim());
    setIsYesNoQuestion(result);
  }, [messages]);

  const handleRecommendClick = async (e) => {
    e.preventDefault();
    await handleSendMessage("Do you have any recommendations?");
  };

  const handleYesClick = async (e) => {
    e.preventDefault();
    await handleSendMessage("Yes");
  };

  const handleNoClick = async (e) => {
    e.preventDefault();
    await handleAssistantResponse(messages, "Okay then, bye for now!");
  };

  const handleAskQuestionClick = async (e) => {
    e.preventDefault();
    await handleSendMessage("Can you ask me a relevant question?");
  };

  const handleBrowseClick = () => {
    navigate(`/kids-zone?grade=${selectedGrade}`);
    window.location.reload();
  };

  return (
    <Flex className={"actions-container"} direction={"row"} padding={"10px"} width={"100%"}>
      {!isViewingActivity && !isYesNoQuestion && !isPlayground && (
        <>
          <button
            className="white-button"
            disabled={isThinking}
            onClick={handleRecommendClick}
          >
            <span>Recommend activities</span>
          </button>
          <button
            className="white-button"
            disabled={isThinking}
            onClick={handleBrowseClick}
          >
            <span>Browse activities</span>
          </button>
        </>
      )}
      {!isYesNoQuestion && (
        <button
          className="white-button"
          disabled={isThinking}
          onClick={handleAskQuestionClick}
        >
          <span>Ask me a question</span>
        </button>
      )}
      {isYesNoQuestion && (
        <>
          <button
            className="white-button"
            disabled={isThinking}
            onClick={handleYesClick}
          >
            <span>Yes</span>
          </button>
          <button
            className="white-button"
            disabled={isThinking}
            onClick={handleNoClick}
          >
            <span>No</span>
          </button>
        </>
      )}
    </Flex>
  );
};

export default ActionBar;
