/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_mobile_analytics_app_id": "d5ca7daba4fd457e946d0e0f874eee15",
    "aws_mobile_analytics_app_region": "us-east-1",
    "Analytics": {
        "AWSPinpoint": {
            "appId": "d5ca7daba4fd457e946d0e0f874eee15",
            "region": "us-east-1"
        }
    },
    "aws_cloud_logic_custom": [
        {
            "name": "aws",
            "endpoint": "https://acyw728pzk.execute-api.us-east-1.amazonaws.com/dev",
            "region": "us-east-1"
        },
        {
            "name": "chatCompletion",
            "endpoint": "https://lrqqmw3lhf.execute-api.us-east-1.amazonaws.com/dev",
            "region": "us-east-1"
        },
        {
            "name": "database",
            "endpoint": "https://bk3970fqib.execute-api.us-east-1.amazonaws.com/dev",
            "region": "us-east-1"
        },
        {
            "name": "emailservice",
            "endpoint": "https://qm69buipn5.execute-api.us-east-1.amazonaws.com/dev",
            "region": "us-east-1"
        },
        {
            "name": "speak",
            "endpoint": "https://18upxkx33l.execute-api.us-east-1.amazonaws.com/dev",
            "region": "us-east-1"
        },
        {
            "name": "stripe",
            "endpoint": "https://lz36uaapkk.execute-api.us-east-1.amazonaws.com/dev",
            "region": "us-east-1"
        }
    ],
    "aws_appsync_graphqlEndpoint": "https://6avyorrfcbdjli5d4umspha4iu.appsync-api.us-east-1.amazonaws.com/graphql",
    "aws_appsync_region": "us-east-1",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_cognito_identity_pool_id": "us-east-1:ba83ba69-798a-4c1e-afdc-1e57b70a12dd",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_diwH78cgW",
    "aws_user_pools_web_client_id": "1goejf14hdg4qperc99v63gsu7",
    "oauth": {},
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ]
};


export default awsmobile;
