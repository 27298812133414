import {useState, useEffect} from 'react';
import _ from 'lodash';
import {upsertUser} from "../helpers/userInfo";
import steps from "../data/studyBuddySteps.json";
import stepsModal from "../data/feedbackSteps.json";
import {StepType, EmotionStepType} from "../constants";

const GRADE_SELECT = steps.data.filter(d => d.type === StepType.GradeSelection)[0]
const EMOTION_SELECT = steps.data.filter(d => d.type === StepType.EmotionSelection)[0]
const ACTIVITY_SELECT = steps.data.filter(d => d.type === StepType.Recommendation)[0]
const ACTIVITY_SELECT_MODAL = stepsModal.data.filter(d => d.type === StepType.Recommendation)[0]
const CONTINUE_ACTIVITY_SELECT_MODAL = stepsModal.data.filter(d => d.type === StepType.CheckStartActivities)[0]
const NAME_INPUT = steps.data.filter(d => d.type === StepType.NameInput)[0]
const EMOTION_FLOW_START_STEP = 5

function useStepChange(isWithFeedback = false, feedback = '', user = '', name = '', buddy = '', grade = '', emotion = '', activity = '', startActivities = null) {
    const [step, setStep] = useState(0);

    // If buddy, grade, name, or user changes update the db
    useEffect(() => {
        if (user && name !== '' && grade !== '' && buddy !== '') {
            upsertUser(user.username, name, grade, buddy)
        }
    }, [buddy, grade, name, user])

    useEffect(() => {
        if (isWithFeedback && feedback !== '') setStep(prevStep => prevStep + 1)
    }, [feedback, isWithFeedback])

    // For name input: skip if we have the name
    useEffect(() => {
        if (!isWithFeedback && step === NAME_INPUT.step && name !== '') setStep(NAME_INPUT.nextStep)
    }, [isWithFeedback, name, step])

    // For grade selection: skip if we have the grade
    useEffect(() => {
        if (!isWithFeedback && step === GRADE_SELECT.step && grade !== '') setStep(EMOTION_FLOW_START_STEP)
    }, [grade, isWithFeedback, step])

    useEffect(() => {
        if (!isWithFeedback && step === EMOTION_SELECT.step && emotion !== '') {
            setStep(EMOTION_SELECT.nextStep + EmotionStepType[emotion] / 10);
        }
    }, [emotion, isWithFeedback, step])

    useEffect(() => {
        if (!isWithFeedback && step === ACTIVITY_SELECT.step && !_.isEmpty(activity)) setStep(ACTIVITY_SELECT.nextStep);
    }, [activity, isWithFeedback, step]);

    useEffect(() => {
        if (isWithFeedback && step === ACTIVITY_SELECT_MODAL.step && !_.isEmpty(activity)) setStep(ACTIVITY_SELECT_MODAL.nextStep);
    }, [activity, isWithFeedback, step]);

    useEffect(() => {
        if (isWithFeedback && step === CONTINUE_ACTIVITY_SELECT_MODAL.step && startActivities) setStep(CONTINUE_ACTIVITY_SELECT_MODAL.nextStep);
        else if (isWithFeedback && step === CONTINUE_ACTIVITY_SELECT_MODAL.step && startActivities !== null && !startActivities) setStep(CONTINUE_ACTIVITY_SELECT_MODAL.nextStep + 0.1);
    }, [isWithFeedback, startActivities, step]);

    return [step, setStep];
}

export default useStepChange;