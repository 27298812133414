import {Flex} from "@aws-amplify/ui-react";
import React, {useEffect, useRef} from "react";
import {upsertUser} from "../../helpers/userInfo";
import useStudyBuddyState from "../../hooks/useStudyBuddyState";

export default function BuddySelector({config, setSelectedBuddy, setDisplayBuddySelector, isPlayground=false, buddyStyles={}}) {
    const {
        user,
        name,
        selectedGrade,
    } = useStudyBuddyState();

    const flexRef = useRef(null);
    const isMobile = window.innerWidth < 769; //eslint-disable-line

    // Add a click event listener to the document
    useEffect(() => {
        setTimeout(() => {
            // Attach the event listener
            document.addEventListener("click", handleClickOutside);
        }, 200);
        const handleClickOutside = (event) => {
            if (flexRef.current && !flexRef.current.contains(event.target)) {
                // Clicked outside the Flex container, so hide the component
                setDisplayBuddySelector(false);
            }
        };
        // Remove the event listener when the component unmounts
        return () => {
            document.removeEventListener("click", handleClickOutside);
        };
    }, []);

    return (
        <Flex
            ref={flexRef} // Assign the ref to the Flex container
            className={'chat-container'}
            style={{
                position: 'absolute',
                width: isMobile ? "250px": `${config.avatar.available.length * 130}px`,
                top: 10,
                left: 100,
                overflow: 'auto',
                ...buddyStyles
            }}
        >
            {
                config.avatar.available.map(buddyId => (
                    <img
                        key={buddyId}
                        src={`/images/avatars/${buddyId}.png`}
                        alt={`${buddyId} buddy`}
                        style={{
                            width: '100px',
                            height: '100px',
                            objectFit: 'contain',
                            cursor: 'pointer'
                        }}
                        onClick={() => {
                            setSelectedBuddy(buddyId);
                            if(!isPlayground) {
                                upsertUser(user.username, name, selectedGrade, buddyId).then(r => {});
                            };
                        }}
                    />
                ))
            }
        </Flex>
    )
}