import React from "react";
import FeedbackSelectionScene from "./FeedbackSelectionScene";
import DialogueScene from "./DialogueScene";
import EmotionSelectionScene from "./EmotionSelectionScene";
import RecommendationScene from "./RecommendationScene";
import RecommendationConfirmationScene from "./RecommendationConfirmationScene";
import { StepType } from "../../constants";
import characters from "../../data/studyBuddyCharacters.json";
import BuddySelection from "./BuddySelection";
import GradeSelectionScene from "./GradeSelectionScene";
import RestScene from "./RestScene";
import NameInputScene from "./NameInputScene";
import ParentTutorialScene from "./ParentTutorialScene";
import CheckStartActivities from "./CheckStartActivities";

const SceneRenderer = ({
  step,
  name = "",
  setName = () => {},
  selectedBuddy = "",
  setSelectedBuddy = () => {},
  selectedGrade = "",
  setSelectedGrade = () => {},
  setFeedback = () => {},
  setSelectedEmotion = () => {},
  recommendations = [],
  selectedActivity,
  setSelectedActivity = () => {},
  setStartActivities = () => {},
  onBackClick = () => {},
  goToNextScene = () => {},
  videoRef = null,
  isVoiceControlled = false,
  sampleRate = 44100,
  isAudio
}) => {
  switch (step.type) {
    case StepType.CharacterSelection:
      return (
        <BuddySelection
          name={name}
          setName={setName}
          characters={characters}
          selectedBuddy={selectedBuddy}
          setSelectedBuddy={setSelectedBuddy}
        />
      );
    case StepType.NameInput:
      return (
        <NameInputScene
          video={step.contentVideo[selectedBuddy]}
          name={name}
          setName={setName}
          isWithBackButton={step.isWithBackButton}
          onBackClick={onBackClick}
          selectedChar={selectedBuddy}
        />
      );
    case StepType.FeedbackSelection:
      return (
        <FeedbackSelectionScene
          video={step.contentVideo[selectedBuddy]}
          isVoiceControlled={isVoiceControlled}
          setFeedback={setFeedback}
          sampleRate={sampleRate}
        />
      );
    case StepType.Dialogue:
      return (
        <DialogueScene
          ref={videoRef}
          dialogueVideo={step.contentVideo[selectedBuddy]}
          isWithBackButton={step.isWithBackButton}
          onBackClick={onBackClick}
          selectedChar={selectedBuddy}
          isAudio={isAudio}
        />
      );
    case StepType.GradeSelection:
      return (
        <GradeSelectionScene
          video={step.contentVideo[selectedBuddy]}
          selectedGrade={selectedGrade}
          setSelectedGrade={setSelectedGrade}
          isWithBackButton={step.isWithBackButton}
          onBackClick={onBackClick}
          isVoiceControlled={isVoiceControlled}
          sampleRate={sampleRate}
        />
      );
    case StepType.Rest:
      return (
        <RestScene
          text={step.contentString[selectedBuddy]}
          video={step.contentVideo[selectedBuddy]}
          goToNextScene={goToNextScene}
        />
      );
    case StepType.EmotionSelection:
      return (
        <EmotionSelectionScene
          video={step.contentVideo[selectedBuddy]}
          setSelectedEmotion={setSelectedEmotion}
          isVoiceControlled={isVoiceControlled}
          sampleRate={sampleRate}
        />
      );
    case StepType.Recommendation:
      return (
        <RecommendationScene
          video={step.contentVideo[selectedBuddy]}
          recommendations={recommendations}
          setActivity={setSelectedActivity}
        />
      );
    case StepType.RecommendationConfirmation:
      return (
        <RecommendationConfirmationScene
          video={step.contentVideo[selectedBuddy]}
          activity={selectedActivity}
          onBackClick={onBackClick}
        />
      );
    case StepType.ParentTutorial:
      return (
        <ParentTutorialScene
          ref={videoRef}
          dialogueVideo={step.contentVideo[selectedBuddy]}
        />
      );
    case StepType.CheckStartActivities:
      return (
        <CheckStartActivities
          video={step.contentVideo[selectedBuddy]}
          setStartActivities={setStartActivities}
          isVoiceControlled={isVoiceControlled}
          sampleRate={sampleRate}
        />
      );
    default:
      return null;
  }
};

export default SceneRenderer;
