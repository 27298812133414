import { useState, useEffect, useCallback, useRef } from 'react'

const noop = () => {}
export const loadImage = (url, callback = noop) => {
  const img = new Image()
  img.onload = () => {
    callback(null, img)
  }
  img.onerror = err => {
    callback(err)
  }
  img.src = url
}

export const useAnimation = ({
  startFrame = 0,
  sprite,
  width,
  height,
  direction = 'horizontal',
  onError = noop,
  onLoad = noop,
  onEnd = noop,
  maxFrames,
  fps = 60,
  shouldAnimate = true,
  stopLastFrame,
  reset,
  scale = 1,
  wrapAfter,
  row,
  frame
}) => {
  const prevTime = useRef()
  const [currentFrame, setCurrentFrame] = useState(startFrame)
  const [spriteWidth, setSpriteWidth] = useState(0)
  const [spriteHeight, setSpriteHeight] = useState(0)
  const [isLoaded, setIsLoaded] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [hasErrored, setHasErrored] = useState(false)
  // const [maxFrames, setMaxFrames] = useState(0)
  const interval = 1000 / fps

  const loadSprite = useCallback(
      (url) => {
        let unmounted = false;
        if (!isLoading && (!isLoaded || !hasErrored)) {
          setIsLoading(true);
          loadImage(url, (err, image) => {
            if (unmounted) {
              return;
            }
            if (err) {
              onError(err);
              setHasErrored(true);
              return;
            }
            onLoad();
            setIsLoaded(true);
            setIsLoading(false);
            setSpriteWidth(image.width);
            setSpriteHeight(image.height);
          });
        }
        return () => (unmounted = true);
      },
      [isLoading, isLoaded, hasErrored, onError, onLoad, loadImage]
  );

  const animate = useCallback(
      (nextFrame, time) => {
        if (!prevTime.current) {
          prevTime.current = time;
        }

        if (shouldAnimate) {
          const delta = time - prevTime.current;
          if (delta < interval) {
            return requestAnimationFrame((time) => animate(nextFrame, time));
          }

          prevTime.current = time - (delta % interval);
          setCurrentFrame(nextFrame);
        } else {
          prevTime.current = 0;
        }
      },
      [shouldAnimate, interval, setCurrentFrame]
  );

  const getSpritePosition = useCallback(
    (frame = 0) => {
      const col = frame

      //the below code is to convert change the rowindex to column if needed in the near future

      // let row, col
      // const isHorizontal = direction === 'horizontal'
      // if (typeof wrapAfter === 'undefined') {
      //   row = isHorizontal ? 0 : frame
      //   col = isHorizontal ? frame : 0
      // } else {
      //   // row = isHorizontal ? Math.floor(frame / wrapAfter) : frame % wrapAfter
      //   // row = frame % wrapAfter;
      //   row=2
      //   col = Math.floor(frame / wrapAfter);
      //   console.log(row, col, "row ==>")
      //   // col =  isHorizontal? frame % wrapAfter:  Math.floor(frame / wrapAfter)
      // }
      const _width = (-width * col) / scale
      const _height = (-height * row) / scale
      return `${_width}px ${_height}px`
    },
    [width, height, scale, row, direction, wrapAfter]
  )

  useEffect(() => {
    setIsLoaded(false)
    setHasErrored(false)
    return loadSprite(sprite)
  }, [sprite])

  useEffect(() => {
    if (shouldAnimate) {
      const nextFrame =
        currentFrame + 1 >= maxFrames ? startFrame : currentFrame + 1

      if (!shouldAnimate) {
        return
      }
      if (currentFrame === maxFrames - 1 && stopLastFrame) {
        return onEnd()
      }

      let id = requestAnimationFrame(time => {
        id = animate(nextFrame, time)
      })
      return () => {
        cancelAnimationFrame(id)
      }
    }
  }, [shouldAnimate, maxFrames, currentFrame, startFrame])

  useEffect(() => {
    setCurrentFrame(startFrame)
  }, [reset])

  useEffect(() => {
    if (typeof frame === 'number' && frame !== currentFrame) {
      setCurrentFrame(frame)
    }
  }, [frame])

  return {
    backgroundImage: isLoaded ? `url(${sprite})` : null,
    backgroundPosition: isLoaded ? getSpritePosition(currentFrame) : null,
    backgroundSize: `${spriteWidth / scale}px ${spriteHeight / scale}px`,
    width: `${width / scale}px`,
    height: `${height / scale}px`,
  }
}