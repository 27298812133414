import config from "./config.json";
import SpeechHelper from "./SpeechHelper";
import { getRecommendations as getRecommendationsFn } from "../../helpers/getRecommendations";
import subjects from "../../data/subjects.json";

// TODO: remove insecure API usage.
const { Configuration, OpenAIApi } = require("openai");

const _openai_configuration = new Configuration({
  organization: process.env["REACT_APP_OPENAPI_ORGANIZATION"],
  apiKey: process.env["REACT_APP_OPENAPI_API_KEY"],
});
const openai = new OpenAIApi(_openai_configuration);

const DEFAULT_SENDER = "assistant";
const TYPE_ACTION = {
  video: "watching",
  game: "playing",
  activity: "interacting with",
  music: "listening to",
  printable: "viewing",
};
const RECOMMENDATION_KEYWORDS = [
  "recommend",
  "recommendation",
  "recommendations",
  "suggest",
  "suggestions",
  "best",
  "favorite",
  "top",
  "good activities",
  "should I do",
  "should I try",
  "what to try",
  "where to go",
  "things to do",
  "must do",
  "must try",
  "do you have",
  "I'd like to",
  "want to play",
  "play games",
  "play a game",
  "listen to music",
  "listen music",
  "watch videos",
  "watch a video",
  "learn math",
  "learn science",
  "learn language arts",
];


class Buddy {
  constructor(
    selectedGrade,
    audioRef,
    context,
    activities = [],
    currentlyViewingActivity = null,
    isPlayground = false,
    isViewingActivity = null
  ) {
    this.context = context;
    this.speechHelper = new SpeechHelper(audioRef, context, config.speech);
    this.activities = activities;
    this.currentlyViewingActivity = currentlyViewingActivity;
    this.isViewingActivity = isViewingActivity;
    this.greetingMessage =
      {
        text: this._buildGreetingMessage(context, currentlyViewingActivity, selectedGrade, isPlayground),
        sender: DEFAULT_SENDER,
        name: this.context.biography.call_me,
      };
    this.systemMessage =
      {
        text: this._buildSystemMessage(),
        sender: "system",
        name: "You",
        hidden: true,
      };
  }

  respondToMessages = async (messages) => {
    console.log('BUDDY responding to messages', messages);
    if (messages.length === 0) {
      // Return the greeting
      return [this.greetingMessage,];
    }

    // Deep copy, and prepend the special system message.
    messages = JSON.parse(JSON.stringify([this.systemMessage, ...messages]));

    const lastMessage = messages[messages.length - 1];
    if (_isMathQuestion(lastMessage.text)) {
      messages[messages.length - 1].text +=
        config.pre_answer_rules["math"].join(" ");
    }

    // Default response
    var responseMessage = {
        text: "I'm having a little trouble understanding right now, try again later.",
        sender: DEFAULT_SENDER,
        name: this.context.biography.call_me,
      };
    try {
      console.log('Messages as sent to chatCompletion', messages);
      const response = await openai.createChatCompletion({
        ...config.api,
        messages: messages.map((msg) => {
          return {
            role: msg.sender,
            content: msg.text,
          };
        }),
      });
      console.log('Response from chatCompletion', response);
      responseMessage.text = response.data.choices[0].message.content;
    } catch (err) {
      console.error(err);
    }

    console.log('BUDDY response', responseMessage);

    return [responseMessage, ];
  };

  speakText = (text) => this.speechHelper.speak(text);


  // TODO: move this up into ChatContainer? Doesn't use AI?
  isAskingForRecommendation = (message) => {
    const words = message
      .replace(/[.?!,;]*/g, "")
      .toLowerCase()
      .split(" ");
    return RECOMMENDATION_KEYWORDS.some((keyword) => {
      const lowerCaseKeyword = keyword.toLowerCase();
      return words.some((word) => word === lowerCaseKeyword);
    });
  };

  getRecommendations = async (
    user,
    grade,
    emotion,
    message,
    subjectsToExclude = []
  ) => {
    if (this.currentlyViewingActivity) {
      // Don't recommend activity when already viewing activity
      return [];
    }

    const subject = _getSubjectFromMessage(message);
    const typeRecommendations = _findObjectsWithType(this.activities, message);

    const emotionRecommendations =
      typeRecommendations.length < 3
        ? await this._getRecommendationsBasedOnEmotion(
            user,
            grade,
            emotion,
            subject ? [subject] : [],
            subjectsToExclude
          )
        : [];

    const keywordRecommendations =
      typeRecommendations.length + emotionRecommendations.length < 3
        ? _findObjectsWithKeyword(this.activities, message)
        : [];

    const recommendations = [
      ...typeRecommendations,
      ...emotionRecommendations,
      ...keywordRecommendations,
    ];

    // Remove currently viewing activity from recommended list
    if (this.currentlyViewingActivity)
      recommendations.filter(
        (activity) => activity.id === this.currentlyViewingActivity.id
      );

    // Do we know their strengths and weaknesses?
    // Do we know what they want to focus on?
    // What day of the week are we?
    // Is there a holiday today?

    _shuffleArray(recommendations);

    return recommendations.slice(0, 3);
  };

  _getRecommendationsBasedOnEmotion = async (
    user,
    grade,
    emotion,
    subjectsToInclude,
    subjectsToExclude
  ) =>
    !emotion
      ? []
      : await getRecommendationsFn(
          user,
          grade,
          emotion,
          3,
          subjectsToInclude,
          subjectsToExclude
        );

  _buildGreetingMessage(context, currentlyViewingActivity, selectedGrade, isPlayground) {
    var greeting =
      context.technical.greetings[
        Math.floor(Math.random() * context.technical.greetings.length)
      ];

    const specialTimeGreeting = _getSpecialTimeGreeting(context.technical.special_greetings.time);
    if (specialTimeGreeting) {
      greeting = `${specialTimeGreeting} ${greeting}`;
    } else {
      const specialDayGreeting = _getDayGreeting(context.technical.special_greetings.day);
      if (specialDayGreeting) greeting = `${specialDayGreeting} ${greeting}`;
    }

    if (config.greeting_additions.messages.length > 0 &&
        Math.random() < config.greeting_additions.chance) {
      const randomIndex = Math.floor(Math.random() * config.greeting_additions.messages.length);
      greeting = `${greeting} \n\n${config.greeting_additions.messages[randomIndex]}`;
    }

    // TODO: Add something based on a holiday
    // TODO: Add something based on a previous activities
    // TODO: Add something based on a previous interaction

    if (currentlyViewingActivity) {
      if (currentlyViewingActivity.hasOwnProperty("ai") &&
          currentlyViewingActivity.ai.hasOwnProperty("greetings")) {
          const randomGreetingIndex = Math.floor(Math.random() * currentlyViewingActivity.ai.greetings.length);
          greeting = currentlyViewingActivity.ai.greetings[randomGreetingIndex];
      } else {
        greeting = `Looks like you're ${
          TYPE_ACTION[currentlyViewingActivity.type]
        } ${
          currentlyViewingActivity.name
        }. That's a good one! Let me know how I can help!`;
      }
    } else {
      greeting = isPlayground ? greeting: `${greeting} \n\nYou can [explore activities on your own](https://www.teachmetv.co/kids-zone?grade=${selectedGrade}), or work on your learning skills, or, play a game. It's your choice.`;
    }
    return greeting;
  }

  _buildSystemMessage() {
    const bio = this.context.biography;
    const rules = [...config.rules];
    if (this.isViewingActivity && this.isViewingActivity()) {
      rules.push(`If the user asks a question about the current activity, please mention that the user is currently interacting with "${this.currentlyViewingActivity.name}"`)
    }
    if (bio.hasOwnProperty("answers")) {
      const answers = bio.answers;
      if (answers.hasOwnProperty("tone"))
        rules.push(`Use this tone when answering: ${answers.tone}`);
      if (answers.hasOwnProperty("voice_of"))
        rules.push(`Use ${answers.voice_of}'s style when answering question.`);
    }
    rules.push(`Going forward, you are ${bio.name}. When asked for your name, you say ${bio.call_me}.`);
    if (bio.hasOwnProperty("birthday"))
      rules.push(`Your birthday is on ${bio.birthday}.`);
    if (bio.hasOwnProperty("age")) rules.push(`Your age is on ${bio.age}.`);
    if (bio.hasOwnProperty("grade"))
      rules.push(`Your grade is on ${bio.grade}.`);
    if (bio.hasOwnProperty("eye_color"))
      rules.push(`Your eyes are ${bio.eye_color}.`);
    if (bio.hasOwnProperty("hair color"))
      rules.push(`Your hair color is ${bio.hair_color}).`);
    if (bio.hasOwnProperty("body")) rules.push(`Your body is ${bio.body}.`);
    if (bio.hasOwnProperty("other_physical_features"))
      rules.push(`Here are your other physical features: ${bio.other_physical_features.join(", ")}.`);
    if (bio.hasOwnProperty("workout_routine"))
      rules.push(`Your workout routine is: ${bio.workout_routine.join(", ")}.`);
    if (bio.hasOwnProperty("country"))
      rules.push(`Your home country is ${bio.country}.`);
    if (bio.hasOwnProperty("spouse"))
      rules.push(`Your partner is ${bio.spouse}.`);
    if (bio.hasOwnProperty("children"))
      rules.push(`Your children are: ${bio.children.join(", ")}.`);
    if (bio.hasOwnProperty("languages"))
      rules.push(`Your languages are: ${bio.languages.join(", ")}.`);
    if (bio.hasOwnProperty("hobbies"))
      rules.push(`Your hobbies are: ${bio.hobbies.join(", ")}.`);
    if (bio.hasOwnProperty("creations"))
      rules.push(`You are the creator of ${bio.creations.join(", ")}. `);
    if (bio.hasOwnProperty("professions"))
      rules.push(`You are a ${bio.professions.join(", ")}.`);
    if (bio.hasOwnProperty("skills"))
      rules.push(`You are your skills: ${bio.skills.join(", ")}.`);
    if (bio.hasOwnProperty("education"))
      rules.push(`Here are your degrees: ${bio.education.join(", ")}.`);
    if (bio.hasOwnProperty("fun_facts"))
      rules.push(`Here are fun facts about you: ${bio.fun_facts.join(", ")}.`);
    if (bio.hasOwnProperty("linkedin_bio"))
      rules.push(`Here is your LinkedIn bio: ${bio.linkedin_bio}.`);
    return rules.join(" ");
  }

}


function _getSubjectFromMessage(message) {
  const subjectsArray = Object.keys(subjects.data).map((subject) =>
    subject.toLowerCase()
  );
  const matchedSubject = subjectsArray.find((subject) =>
    message.toLowerCase().includes(subject)
  );
  return matchedSubject || null;
};

function _getSpecialTimeGreeting(data) {
  const currentHour = new Date().getHours();
  for (let period in data) {
    if (Math.random() < 0.5) continue;
    const { gt, lt, message } = data[period];
    if ((gt === undefined || currentHour > gt) &&
        (lt === undefined || currentHour < lt)) {
      return message;
    }
  }
  return null; // Default greeting if none of the conditions match
}

function _getDayGreeting(data) {
  if (Math.random() < 0.5) return null;
  const days = [
    "sunday",
    "monday",
    "tuesday",
    "wednesday",
    "thursday",
    "friday",
    "saturday",
  ];
  const currentDay = days[new Date().getDay()];
  const greetingsForToday = data[currentDay];
  if (greetingsForToday && greetingsForToday.length > 0) {
    const randomIndex = Math.floor(Math.random() * greetingsForToday.length);
    return greetingsForToday[randomIndex];
  }
  return null; // Default greeting if none of the conditions match
}

function _shuffleArray(array) {
  for (let i = array.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [array[i], array[j]] = [array[j], array[i]]; // Swap elements
  }
}

function _isMathQuestion(message) {
  // Regular expressions for common math terms and symbols
  const patterns = [
    /\d+ \+ \d+/, // Addition symbol
    /\d+ - \d+/, // Subtraction symbol
    /\d+ \* \d+/, // Multiplication symbol
    /\d+ \/ \d+/, // Division symbol
    /sqrt/, // Square root
    /cube root/, // Cube root
    /power of/, // Power of
    /logarithm/, // Logarithm
    /integral/, // Calculus
    /derivative/, // Calculus
    /\^/, // Exponentiation
    /factorial/, // Factorial
    /sin|cos|tan/, // Trigonometry
    /angle/, // Geometry
    /area/, // Geometry
    /perimeter/, // Geometry
    /solve for/, // Algebra
    /equation/, // General math problems
    /sum/, // Summation
    /\bpi\b/, // Pi
    /addition/, // Addition term
    /subtraction/, // Subtraction term
    /division/, // Division term
    /multiplication/, // Multiplication term
    /fractions?/, // Fraction or Fractions
  ];

  for (let pattern of patterns) {
    if (pattern.test(message.toLowerCase())) return true;
  }

  return false;
};

function _findObjectsWithKeyword(
  objectsArray,
  searchString,
  maxNumberObjects = 3
) {
  let matchingObjects = [];
  let count = 0;

  for (let obj of objectsArray) {
    if (count >= maxNumberObjects) {
      break; // Exit if we've reached the max number of matching objects
    }

    for (let keyword of obj.keywords) {
      if (
        searchString &&
        keyword &&
        searchString.toLowerCase().includes(` ${keyword.toLowerCase()} `)
      ) {
        matchingObjects.push(obj);
        count++;
        break; // Exit the inner loop to avoid pushing the same object multiple times
      }
    }
  }

  return matchingObjects;
};

function _findObjectsWithType(objectsArray, searchString, maxNumberObjects = 3) {
  const array = [...objectsArray];
  _shuffleArray(array);
  let matchingObjects = [];
  let count = 0;

  for (let obj of array) {
    if (count >= maxNumberObjects) {
      break; // Exit if we've reached the max number of matching objects
    }

    if (
      searchString &&
      searchString.toLowerCase().includes(` ${obj.type.toLowerCase()}`)
    ) {
      matchingObjects.push(obj);
      count++;
    }
  }

  return matchingObjects;
};

export default Buddy;
