import {useContext, useEffect} from "react";
import {Button, Flex, Grid, Heading, Image, Text, View} from "@aws-amplify/ui-react";
import Card from "./activity-card";
import Breadcrumbs from "./breadcrumbs";
import {BackButton, FlexCenteredColumn, SectionHeading} from "../../themed";
import SearchBar from "../../components/search-bar";
import typesDict from "../../data/activityTypes.json";
import {Selectors} from "./selectors";
import {TutorialContext} from "../Onboarding/TutorialWrapper";
import {getFilteredActivitiesByGrade} from "../../helpers/getActivities";

export default function KidsZoneActivities({
                                               activitiesRef,
                                               audioRef,
                                               isMobile,
                                               activities,
                                               grade,
                                               subject,
                                               type,
                                               searchTerm,
                                               page,
                                               setSelectedGrade,
                                               setSelectedSubject,
                                               setSelectedType,
                                               setSearchTerm,
                                               setPage,
                                           }) {

    const {
        setRun,
        stepIndex,
        setStepIndex,
        isTourActive,
        isSelectorHighlight,
        setIsSelectorHighlight
    } = useContext(TutorialContext)

    useEffect(() => {
        if (isTourActive && !isSelectorHighlight && stepIndex === 1) {
            setRun(true)
            setStepIndex(2)
        } else if (isTourActive && isSelectorHighlight && stepIndex === 2) {
            setRun(true)
            setStepIndex(3)
            setIsSelectorHighlight(false)
        }
    }, [isSelectorHighlight, isTourActive, setIsSelectorHighlight, setRun, setStepIndex, stepIndex])

    useEffect(() => {
        if (audioRef.current)
            audioRef.current.pause();
    }, [audioRef]);

    const NUM_ACTIVITIES_PER_PAGE = 8

    const SUBJECT_ICONS = {
        'math': {
            left: 'icon-math',
            right: 'shapes.svg',
        },
        'language-arts': {
            left: 'icon-languagearts',
            right: 'shape-book.png',
        },
        'science': {
            left: 'icon-science',
            right: 'shape-microscope.svg'
        },
        'music': {
            left: 'icon-music',
            right: 'shape-heart.svg'
        },
        'art': {
            left: 'icon-art',
            right: 'shape-canvas.svg'
        },
        'exercise': {
            left: 'icon-exercise',
            right: 'shape-dance.png'
        },
        'kids-teaching-kids': {
            left: 'ktk',
            right: 'shape-ktk.png'
        }
    }

    function searchArray(array, searchTerm) {
        searchTerm = searchTerm.toLowerCase();
        const result = array.filter(function (element) {
            const el = element.toLowerCase();
            return el.split(" ").includes(searchTerm) || el === searchTerm;
        });

        return result.length > 0
    }

    const filterActivities = () => {
        let filteredActivities = grade ? getFilteredActivitiesByGrade(activities, grade || 'K') : activities?.data

        if (subject && subject !== '')
            filteredActivities = filteredActivities.filter(a => a.subjects.includes(titleCase(subject.replaceAll('-', ' '))))

        if (type && type !== '')
            filteredActivities = filteredActivities.filter(a => a.type.toLowerCase() === type.toLowerCase())

        if (searchTerm && searchTerm !== '')
            filteredActivities = filteredActivities.filter(a =>
                (
                    searchArray(a.keywords
                        .filter(k => k != null && typeof k === 'string')
                        .map(k => k.toLowerCase()), searchTerm) ||
                    searchArray(a.keywords.filter(k => k != null && typeof k === 'string'), searchTerm) ||
                    searchArray(a.subjects, searchTerm) ||
                    a.type === searchTerm.toLowerCase() ||
                    searchTerm.toLowerCase() === `${a.type}s` ||
                    a.name.toLowerCase().includes(searchTerm.toLowerCase())
                )
            )

        // TODO: Loop through all activities to filter out PDFs that are already set as an external link in one of the other activities

        return filteredActivities;
    }

    const filteredActivities = filterActivities()
    filteredActivities.sort((a, b) => (a.priority || 3) - (b.priority || 3))

    useEffect(() => {
        if (searchTerm)
            window.scrollBy({top: 250});
    }, [searchTerm]);

    const pages = () => {
        const pages = []

        for (let i = 0; i <= Math.ceil(filteredActivities.length / NUM_ACTIVITIES_PER_PAGE); i++) {
            let color = {}
            if (page === (i + 1)) color = {color: 'red'}

            pages.push(<Button
                key={'button' + i}
                variation={'link'}
                onClick={() => setPage(i + 1)}
                {...color}
            >
                {i + 1}
            </Button>)
        }

        return pages
    }

    const SubjectIcon = () => {
        if (isMobile)
            return;

        return <Flex
            direction={'column'}
            alignItems={'center'}
            justifyContent={'center'}
            maxWidth={!isMobile ? '150px' : '100px'}
        >
            {subject && SUBJECT_ICONS[subject].left !== '' &&
                <Image
                    alt={`${subject} icon`}
                    src={`/images/landing/${SUBJECT_ICONS[subject].left}.png`}
                />
            }
            {!subject && <View/>}
            <Heading
                level={2}
                fontFamily={'muli, sans-serif'}
                textAlign={'center'}
                fontSize={'3vw'}
            >
                {
                    (subject && subject.replaceAll('-', ' ').toUpperCase())
                }
            </Heading>
        </Flex>
    }

    const SubjectImage = () => {
        if (isMobile)
            return;

        return <>
            {subject && SUBJECT_ICONS[subject].right !== '' &&
                <Image
                    alt={`shapes`}
                    src={`/images/kids-zone/${SUBJECT_ICONS[subject].right}`}
                    maxWidth={!isMobile ? '150px' : '100px'}
                    style={{
                        objectFit: 'contain',
                        objectPosition: '50% 50%'
                    }}
                />
            }
            {!subject && <View/>}
        </>
    }

    const uniqueActivityTypes = [...new Set(filteredActivities.map(item => item.type))];
    const availableTypes = Object.keys(typesDict.data).filter(type => uniqueActivityTypes.includes(type))


    return (<>
        {grade && <Breadcrumbs
            setSelectedGrade={setSelectedGrade}
            setSelectedSubject={setSelectedSubject}
            setSelectedType={setSelectedType}
            setSearchTerm={setSearchTerm}
            grade={grade}
            subject={subject}
            type={type}
            searchTerm={searchTerm}
        />}

        <Flex
            direction={'column'}
            alignItems={'center'}
            justifyContent={'center'}
        >
            <Grid templateColumns={'1fr 5fr 1fr'} width={'100%'} alignItems={'center'} marginBottom={'50px'}>
                <View>
                    <BackButton
                        onClick={() => {
                            setSelectedType(null);
                            setPage(null);
                            setSelectedSubject(null);
                            setSearchTerm(null);
                        }}
                    />
                </View>
                {type !== '' && type !== null ? (
                    <SectionHeading>{titleCase(type)}</SectionHeading>
                ) : (
                    subject && <SectionHeading>{titleCase(subject.replaceAll('-', ' '))}</SectionHeading>
                )}
            </Grid>

            <Flex
                width={'100%'}
                justifyContent={'space-around'}
                position={'relative'}
                top={-70}
            >
                <SubjectIcon/>

                <FlexCenteredColumn>
                    {
                        availableTypes.length > 1 &&
                        <div
                            id={'step-2'}
                            onClick={() => {
                                setRun(false)
                                setIsSelectorHighlight(true)
                            }}
                        >
                            <Selectors
                                selector={type}
                                setSelectorFn={(type) => {
                                    setSelectedType(type);
                                    setPage(1);
                                    activitiesRef.current.scrollIntoView({
                                        behavior: "smooth", block: "start", inline: "nearest"
                                    })
                                }}
                                sourceDict={
                                    {
                                        ...typesDict, data: Object.fromEntries(
                                            Object.entries(typesDict.data)
                                                .filter(([key, value]) => availableTypes.includes(key))
                                        )
                                    }
                                }/>
                        </div>
                    }

                    <SearchBar
                        isMobile={isMobile}
                        activities={filterActivities()}
                        setSearchTerm={setSearchTerm}
                        playLowerLabelAudio={false}
                        style={{width: '100%'}}
                    />
                </FlexCenteredColumn>

                <SubjectImage/>
            </Flex>
            <Flex direction={'column'} alignItems={'center'} justifyContent={'center'}>
                <Heading level={2} fontFamily={'muli, sans-serif'}>
                    Activities
                </Heading>
                {pages().length === 0 &&
                    <Text>No other activities</Text>
                }
                <Flex
                    ref={activitiesRef}
                    justifyContent={'center'}
                    alignItems={'flex-start'}
                    wrap={'wrap'}
                    marginTop={20}
                >
                    {filteredActivities
                        .splice((page - 1) * NUM_ACTIVITIES_PER_PAGE, NUM_ACTIVITIES_PER_PAGE)
                        .map((activity, index) => (
                            <Card key={'card' + index} index={index} activity={activity} isMobile={isMobile}/>
                        ))
                    }
                </Flex>
            </Flex>
            {pages().length > 0 &&
                <Flex padding={30} wrap={'wrap'} justifyContent={'center'} alignItems={'center'}>
                    <Text>Page </Text>
                    {pages()}
                </Flex>
            }
        </Flex>
    </>)
}

const titleCase = (str) => {
    return str.replace(/\w\S*/g, function (txt) {
        return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    })
}