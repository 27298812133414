import React, {useEffect} from 'react';
import styled from "styled-components";
import {Heading, View} from "@aws-amplify/ui-react";
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import {useNavigate} from "react-router-dom";
import useStudyBuddyState from "../../hooks/useStudyBuddyState";
import {getUserById, upsertUser} from "../../helpers/userInfo";
import AvatarAnimation from "../../components/Buddy/AvatarAnimation";
import { useMobile } from '../../hooks/useMobile';

const Container = styled(View)`
  height: 100vh;
  position: relative;
  background: radial-gradient(140.41% 81.82% at -15.33% 38.49%, rgba(199, 108, 255, 0.20) 0%, rgba(255, 188, 105, 0.20) 100%), #FFFCF8;
  padding: 0 4rem;

  @media only screen and (max-width: 768px) {
    padding: 0;
  }
`

const Title = styled(Heading)`
  padding: 2rem 0;
`

const CharacterContainer = styled(View)`
  display: flex;
  gap: 2rem;
  width: 100%;
  justify-content: center;
  align-items: center;
  padding: 2rem;

  @media only screen and (max-width: 768px) {
    flex-direction: column;
  }
`

const CharacterButton = styled.button`
  border-radius: 20px;
  cursor: pointer;
  border: 2px solid transparent;
  transition: border-color 0.3s ease;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  padding: 2rem;

  @media only screen and (max-width: 768px) {
    width: 90%;
    height: 300px;
    padding: 1rem;
  }

  &.parents {
    background-color: #F2E8FF;

    &:hover {
      border: 2px solid #4F32FF;
    }
  }

  &.kids {
    background-color: white;

    &:hover {
      border: 2px solid #FF8B00;
    }
  }
`

const CharacterImg = styled.img`
  height: 80%;
  margin-bottom: 2rem;
  transition: transform 0.3s ease;

  ${CharacterButton}:hover & {
    transform: translateY(-10px);
  }

  @media only screen and (max-width: 768px) {
    height: 70%;
  }
`

const ButtonLabel = styled(View)`
  width: 100%;
  display: flex;
  gap: 0.5rem;
  font-weight: bold;
  font-size: 2rem;
  align-items: center;

  & > svg {
    transition: transform 0.3s ease;
    transform-origin: 0;
  }

  ${CharacterButton}:hover & > svg {
    transform: scaleX(1.2);
  }

  @media only screen and (max-width: 768px) {
    justify-content: center;
  }
`

const STUDY_BUDDY_RESIZE_RATIO = 540;

function Index() {
    const navigate = useNavigate();

    const {user, name, isFirstVisit, selectedBuddy, context} = useStudyBuddyState()
    const { width: deviceWidth, isMobile} = useMobile();

    // If first visit, create an empty User
    useEffect(() => {
        if (user) {
            getUserById(user.username).then(userInfo => {
                if (!userInfo) {
                    upsertUser(user.username, '', '', '', true)
                }
            })
        }
    }, [user])

    const onClickParent = () => {
        if (isFirstVisit) navigate('/onboarding/parentBuddy')
        else navigate('/accountInformation')
    }

    return (
        <Container height={'100%'}>
            <Title level={1}>Who’s using TeachMe TV Today?</Title>
            <CharacterContainer>
                <CharacterButton className={'parents'} onClick={onClickParent}>
                    <CharacterImg
                        src={`/images/avatars/pam.png`}
                        alt={`Parent character`}
                    />
                    <ButtonLabel>
                        Parents
                        <ArrowForwardIcon/>
                    </ButtonLabel>
                </CharacterButton>
                <CharacterButton className={'kids'} onClick={() => navigate('/studyBuddy')}>
                    {context &&
                        <AvatarAnimation
                            avatarId={selectedBuddy.toLowerCase()}
                            animationName={context.avatar.idle_animations[0]}
                            scale={(isMobile ? (deviceWidth*4) : STUDY_BUDDY_RESIZE_RATIO) / context.avatar.sprite.width}
                        />
                    }
                    <ButtonLabel>
                        {name !== '' ? name : 'Kids'}
                        <ArrowForwardIcon/>
                    </ButtonLabel>
                </CharacterButton>
            </CharacterContainer>
        </Container>
    );
}

export default Index;