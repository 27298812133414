import React, {useEffect, useState} from "react";
import SpeechBubble from "./SpeechBubble";
import { useAnimation } from "../../hooks/useAnimation";
import { useMobile } from "../../hooks/useMobile";

const SPRITE_SHEET_DIMENSION = 512;
const FPS = 10;
const VALID_SPRITE_SHEET_COUNT = 13

function AvatarAnimation(props) {
  const { avatarId, animationName, isThinking, onClick, scale, additionalStyles, children} = props;
  const { width: deviceWidth} = useMobile();

  const [animations, setAnimations] = useState(null);

  const scaleBasesonWidth = deviceWidth<SPRITE_SHEET_DIMENSION ? SPRITE_SHEET_DIMENSION/deviceWidth : 1;

  // TODO: do we need to reimplement 'next' field for animations?

  useEffect(() => {
    if (!avatarId) return;
    import(`./data/${avatarId}.json`)
      .then((module) => {
        setAnimations(module.default.avatar.animations);
      })
      .catch((error) => {
        console.error("Error loading module:", error);
      });
  }, [avatarId]);

  const style = useAnimation({
    sprite: `/images/avatars/${avatarId}_spritesheet.png`,
    width: SPRITE_SHEET_DIMENSION,
    height: SPRITE_SHEET_DIMENSION,
    fps: FPS,
    scale: scale ? scale : scaleBasesonWidth,
    row: animations ? animations[animationName].row: null,
    maxFrames: animations ? (animations[animationName].numColumns || VALID_SPRITE_SHEET_COUNT) : null,
    ...additionalStyles
  })

  // TODO: fix speechbubble in playground
  return (<div className="avatar-animation" style={style} onClick={onClick}>
      {isThinking && <SpeechBubble />}
      {children}
    </div>);
}

export default AvatarAnimation;