import React, { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import MarkdownRenderer from "./MarkdownRenderer";
import SendIcon from "@mui/icons-material/Send";
import MicIcon from "@mui/icons-material/Mic";
import CachedIcon from "@mui/icons-material/Cached";
import Buddy from "./Buddy";
import getActivities from "../../helpers/getActivities";
import useStudyBuddyState from "../../hooks/useStudyBuddyState";
import AudioRecorder from "../audio-recorder";
import useMic from "../../hooks/useMic";
import { Grid, Link, Typography } from "@mui/material";
import AvatarAnimation from "./AvatarAnimation";
import BuddySelector from "./BuddySelector";
import { getActivityHistory } from "../../helpers/getActivityHistory";
import { UserProfile } from "../../models";
import { DataStore } from "aws-amplify";
import ActionBar from "./ActionBar";

const audioContext = new AudioContext();

const PREFERENCE_KEY_PHRASES = [
  "I like",
  "I prefer",
  "My favourite",
  "I play",
  "I love",
  "I’m a fan of",
  "I have a liking for",
  "I opt for",
  "I appreciate",
  "My thing",
  "Soft spot for",
  "Lean towards",
  "I feel drawn to",
  "I can’t resist",
  "The best is",
];

const ChatContainer = (props) => {
  const {
    config,
    defaultContext,
    videoRef,
    handleCloseDialog,
    messages,
    setMessages,
    onAssistantResponded,
    selectedBuddy,
    setSelectedBuddy,
    isPlayground = false,
    triesLeft,
    setTriesLeft,
  } = props;
  const messagesContainerRef = useRef(null);
  const audioRef = useRef(null);
  const {
    user,
    name,
    selectedGrade,
    selectedEmotion,
    isVoiceControlled,
    setIsVoiceControlled,
  } = useStudyBuddyState(isPlayground);

  const sampleRate = useMic(
    isVoiceControlled,
    setIsVoiceControlled,
    config.transcribe.isAllowed
  );

  const location = useLocation();
  const navigate = useNavigate();

  const [context, setContext] = useState(defaultContext);
  const [animationName, setAnimationName] = useState("intro");
  const [message, setMessage] = useState("");
  const [assistant, setAssistant] = useState(null);
  const [activities, setActivities] = useState(null);
  const [activitiesById, setActivitiesById] = useState(null);
  const [recommendedActivities, setRecommendedActivities] = useState([]);
  const [isRecording, setIsRecording] = useState(false);
  const [isThinking, setIsThinking] = useState(false);
  const [displayBuddySelector, setDisplayBuddySelector] = useState(false);
  const [displayActionBar] = useState(true);

  const textareaRef = useRef(null);

  // Run once (dependencies are '[]')
  useEffect(() => {
    if (!context) return;

    getActivities().then((activities) => {
      if (textareaRef.current) {
        textareaRef.current.focus();
      }

      if (activities.hasOwnProperty("data")) activities = activities.data;

      const namedActivities = activities.reduce((obj, item) => {
        obj[item.name] = item.id;
        return obj;
      }, {});
      setActivities(namedActivities);

      const tempActivitiesById = activities.reduce((obj, item) => {
        obj[item.id] = item;
        return obj;
      }, {});
      setActivitiesById(tempActivitiesById);

      const newAssistant = new Buddy(
        selectedGrade,
        audioRef,
        context,
        activities,
        getCurrentlyViewingActivity(tempActivitiesById),
        isPlayground,
        isViewingActivity
      );
      setAssistant(newAssistant);
    });
  }, [context]);  // Run once on startup. TODO: do this right.

  useEffect(() => {
    if (!assistant) return;
    // Kick off the first response (which is a greeting)
    assistant.respondToMessages(messages).then((newResponses) => {
      handleAssistantResponses(newResponses);
    });
    addUserProfileToMessages(user, name, selectedGrade, selectedBuddy);
  }, [assistant]);

  // Scroll to the bottom of the messages container whenever messages.length changes.
  useEffect(() => {
    if (messages.length && messagesContainerRef.current) {
      setTimeout(() => {
        messagesContainerRef.current.scrollTop = messagesContainerRef.current.scrollHeight;
      }, 100);  // Slight delay to let the ui update first?
    }
  }, [messages.length]);

  useEffect(() => {
    if (!selectedBuddy) return;
    setMessages([]);
    import(`./data/${selectedBuddy}.json`)
      .then((module) => {
        const newContext = module.default;
        audioRef.current.pause();
        setContext(newContext);
        setAnimationName("intro");
        setDisplayBuddySelector(false);
      })
      .catch((error) => {
        console.error("Error loading module:", error);
      });
  }, [selectedBuddy]);


  // Update the context whenever the location changes.
  useEffect(() => {
    if (!assistant) return;
    if (!isViewingActivity()) return;
    const activityId = location.pathname.substring(6);
    const activity = activitiesById[activityId];
    const newMessage = {
      text: `Context: I'm viewing a TeachMe TV activity called ${activity.name}. Here's what this activity is about: ${activity.description}`,
      sender: "user",
      name: "You",
      hidden: true,
    };
    assistant.respondToMessages([newMessage]);
  }, [location]);

  const getSelectedEmotion = () => selectedEmotion || "ok";

  const handleAudioEnd = () => {
    if (!isThinking || isPlayground) {
      const randomIndex = Math.floor(Math.random() * context.avatar.idle_animations.length);
      setAnimationName(context.avatar.idle_animations[randomIndex]);
    } else {
      setAnimationName(
        context.buddy.animations.hasOwnProperty("thinking_intro")
          ? "thinking_intro"
          : "thinking"
      );
    }
  };

  const handleAudioPlay = () => {
    setAnimationName("talking");
  };

  const receiveTranscribedMessage = async (msg) => {
    if (msg.trim().length === 0) return;

    setIsRecording(false);
    textareaRef.current.focus();
    setMessage(msg);
  };

  const animateCommand = (message) => {
    const command = message.split(" ")[0];
    if (Object.keys(context.buddy.animations).includes(command)) {
      setAnimationName(command);
      setMessage("");
    }
  };

  const isViewingActivity = () => location.pathname.includes("/play/");

  const getCurrentlyViewingActivity = (activitiesToUse) => {
    if (!isViewingActivity()) return null;
    const activities = activitiesToUse || activitiesById;
    return activities[location.pathname.substring(6)];
  };

  const handleSendMessage = async (message) => {
    if (!message) return;
    if (!message.trim()) return;
    if (isPlayground && triesLeft > 0) setTriesLeft(triesLeft - 1);
    if (isPlayground && triesLeft <= 0) return;

    // Why?
    if (audioContext.state === "suspended") await audioContext.resume();

    videoRef.current?.play();

    if (message[0] === "/") {
      // It's a command
      animateCommand(message.substring(1));
      return;
    }

    tryUpdatingUserProfile(message);

    const yourMessage = { text: message, sender: "user", name: "You" };

    const hasRecommendations = await tryGivingRecommendations(message, yourMessage);
    if (hasRecommendations) return;

    setIsThinking(true);
    setMessage("");

    const msgs = [...messages, yourMessage];
    setMessages((m) => [...m, yourMessage]);

    const responses = await assistant.respondToMessages(msgs);
    await handleAssistantResponses(responses);
  };

  const tryUpdatingUserProfile = async (message) => {
    if (isPlayground) return;
    const preferenceSentence = detectPreferenceSentence(message);
    if (!preferenceSentence) return;

    // TODO: append the preference sentence instead of replacing it.
    try {
      const newPreference = new UserProfile({
        userId: user?.username,
        buddyId: selectedBuddy,
        sentence: preferenceSentence,
      });
      await DataStore.save(newPreference);
      console.log("Preferences updated", preferenceSentence);
    } catch (e) {
      console.error(e);
    }
  };

  const addUserProfileToMessages = async (user, name, grade, buddyId) => {
    if (!user || !name || !grade || !buddyId) return;
    const preferences = await DataStore.query(
      UserProfile,
      (userProfile) =>
        userProfile.userId("eq", `${user.username}`) &&
        userProfile.buddyId("eq", buddyId)
    );
    const userProfileMessage = {
      text: `My name is ${name}} and I'm in grade ${grade}. ${preferences
        .map((p) => p.sentence)
        .join(". ")}`,
      sender: "user",
      name: "You",
      hidden: true,
    };
    console.log("USER PROFILE MESSAGE", userProfileMessage);
    setMessages((m) => [...m, userProfileMessage]);
  };


  const detectPreferenceSentence = (str) => {
    // Split the input string into sentences.
    const sentences = str.match(/[^.!?]+[.!?]+|[^.!?]+$/g) || [];

    for (const phrase of PREFERENCE_KEY_PHRASES) {
      for (const sentence of sentences) {
        if (phrase.split(" ").every((word) => sentence.includes(word)))
          return sentence.trim(); // Return the detected preference sentence.
      }
    }

    return null;
  };

  const tryGivingRecommendations = async (message, yourMessage) => {
    if (!assistant.isAskingForRecommendation(message)) return;

    try {
      return await giveRecommendations(yourMessage);
    } catch (e) {
      console.error(e);
    }
  };

  const handleAssistantResponses = async (responses) => {
    for (let response of responses) {
      if (response.hidden) continue;
      var speechResponse = await assistant.speakText(response.text);
      // Make an excuse for why speach isn't working.
      if (!speechResponse) {
        console.log('No speech response')
        response.text = `(Sorry I can't talk right now because I'm in a quiet space)\n\n${response.text}`;
      }
      setMessages((m) => [...m, response]);
      if (onAssistantResponded) onAssistantResponded(response.text);
    }
    setIsThinking(false);
  };

  const formatRecommendation = (activity) =>
  `\n\n [${activity.name}](/play/${activity.id}) \n\n <img href="/play/${activity.id}" style="width: 60%; cursor: pointer;" src="https://teachmetv.s3.amazonaws.com/images/thumbnails/${activity.thumbnail}.png" alt="${activity.name}"/>`

  const getLastActivitySubjects = (activityHistory) =>
    activityHistory?.length? activitiesById[activityHistory.slice(-3)[0].activityID].subjects: false;

  // Check if the subjects of the last activity are the same in the last 3 activities
  const hasSameSubjectInLastThree = (
    lastThreeActivities,
    lastActivitySubjects
  ) =>
    lastThreeActivities.every(
      (activity) =>
        JSON.stringify(activitiesById[activity.activityID].subjects) ===
        JSON.stringify(lastActivitySubjects)
    );

  const giveRecommendations = async (previousMessage) => {
    const activityHistory = await getActivityHistory(user);
    const lastActivitySubjects = getLastActivitySubjects(activityHistory);

    if (
      activityHistory.length >= 3 &&
      hasSameSubjectInLastThree(activityHistory.slice(-3), lastActivitySubjects)
    )
      return await appendRecommendationsToMessages(
        previousMessage,
        `You've done a lot of ${lastActivitySubjects.join(
          ", "
        )} activities. Would you like to try something different? Here are some suggestions: `,
        lastActivitySubjects
      );
    else
      return await appendRecommendationsToMessages(
        previousMessage,
        `I recommend these activities:`
      );
  };

  const appendRecommendationsToMessages = async (
    previousMessage,
    introMessage,
    subjects
  ) => {
    let newMessage = "";
    const recommendations = (
      await assistant.getRecommendations(
        user?.username,
        selectedGrade,
        getSelectedEmotion(),
        previousMessage.text,
        subjects
      )
    ).filter((activity) => !recommendedActivities.includes(activity.id));
    addToRecommendedActivities(recommendations.map((activity) => activity.id));
    recommendations.forEach((recommendedActivity) => {
      newMessage += formatRecommendation(recommendedActivity);
    });
    const assistantMessage = {
      text: `${introMessage} ${newMessage}`,
      sender: "assistant",
      name: context.biography.call_me,
    };
    const msgs = [...messages, previousMessage, assistantMessage];
    setMessages(msgs);
    resetView();

    return true;
  };

  const resetView = () => {
    setMessage("");
  };

  // Function to add elements to the array and update state correctly
  const addToRecommendedActivities = (newElements) =>
    setRecommendedActivities((prevRecommendedActivities) => [
      ...prevRecommendedActivities,
      ...newElements,
    ]);

  return (
    <Grid
      container
      style={{
        backgroundColor: config.ui.chat_box?.hasOwnProperty("color")
          ? config.ui.chat_box.color
          : "#E0E8F1",
      }}
    >
      <Grid item xs={12} sm={6} display={'flex'} alignContent={'center'} justifyContent={'center'}>
        {config.avatar.available.length > 0 && !isPlayground && (
          <button
            className="white-button"
            style={{
              position: "absolute",
              top: 30,
              left: 10,
              width: "100px",
              height: "64px",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              zIndex: 9999,
            }}
            onClick={() => {
              setDisplayBuddySelector(true);
            }}
          >
            <CachedIcon sx={{ color: "black" }} />
            <img
              src={`/images/avatars/${context?.id}.png`}
              alt={`${context?.id} buddy`}
              style={{
                width: "48px",
                height: "48px",
              }}
            />
          </button>
        )}
        {displayBuddySelector && (
          <BuddySelector
            config={config}
            setSelectedBuddy={setSelectedBuddy}
            setDisplayBuddySelector={setDisplayBuddySelector}
            isPlayground={isPlayground}
          />
        )}
        <Grid>
        <AvatarAnimation
          avatarId={selectedBuddy}
          animationName={animationName}
          isThinking={isThinking}
        />
        </Grid>
      </Grid>

      <Grid item xs={12} sm={6} padding={isPlayground ? 2 : 0}>
        <div
          className="chat-container"
          style={{ minWidth: isPlayground ? "unset" : "400px" }}
        >
          {isRecording && (
            <AudioRecorder
              sampleRate={sampleRate}
              callbackFn={receiveTranscribedMessage}
            />
          )}
          <audio
            ref={audioRef}
            playsInline
            onEnded={handleAudioEnd}
            onPlay={handleAudioPlay}
          />
          <div ref={messagesContainerRef} className="messages-container">
            {messages
              .filter((msg) => !msg.hidden)
              .map((msg, index) => (
                <div
                  key={index}
                  className={`message ${msg.sender.toLowerCase()}`}
                >
                  <strong>{msg.name}: </strong>
                  <MarkdownRenderer
                    markdownContent={msg.text}
                    context={context}
                    sender={msg.sender.toLowerCase()}
                    assistant={assistant}
                    handleCloseDialog={handleCloseDialog}
                  />
                </div>
              ))}
          </div>
          {displayActionBar && (
            <ActionBar
              messages={messages}
              handleSendMessage={handleSendMessage}
              handleAssistantResponse={handleAssistantResponses}
              isThinking={isThinking || (isPlayground && triesLeft === 0)}
              isViewingActivity={isViewingActivity()}
              selectedGrade={selectedGrade}
              isPlayground={isPlayground}
            />
          )}
          <div className="input-container">
            <textarea
              ref={textareaRef}
              value={message}
              disabled={isThinking || (isPlayground && triesLeft === 0)}
              onChange={(e) => setMessage(e.target.value)}
              onKeyDown={async (e) => {
                if (e.key === "Enter" && !e.shiftKey) {
                  handleSendMessage(message);
                  e.preventDefault();
                }
              }}
              placeholder="Type your message..."
              rows="1" // This sets the initial number of rows. You can adjust this.
              style={{
                overflowY: "auto",
                resize: "none",
                maxHeight: "500px",
                boxSizing: "border-box", // to include padding and border in total height
              }}
            />

            {/* Not recording but a message got typed: Show send button */}
            {(!config.transcribe.isAllowed ||
              (!isRecording && message.trim().length > 0) ||
              !isVoiceControlled) && (
              <button
                disabled={isThinking || (isPlayground && triesLeft === 0)}
                className={`send-button ${message.length > 2 ? "pulsate" : ""}`}
                onClick={async () => {
                  handleSendMessage(message);
                }}
              >
                <SendIcon />
              </button>
            )}

            {/* Not recording and nothing typed yet: show recording icon */}
            {config.transcribe.isAllowed &&
              message.trim().length === 0 &&
              isVoiceControlled && (
                <button
                  disabled={isThinking || (isPlayground && triesLeft === 0)}
                  className={`send-button ${!isRecording ? "pulsate" : "recording"}`}
                  background-color="red"
                  onClick={async () => setIsRecording((r) => !r)}
                >
                  <MicIcon />
                </button>
              )}
          </div>
        </div>

        {isPlayground && (
          <>
            <Typography padding={2}>
              {triesLeft !== 0 ? triesLeft : "No"} more messages left
            </Typography>
            <Link
              style={{ cursor: "pointer" }}
              component="button"
              variant="body1"
              onClick={() => navigate("/contact")}
            >
              {triesLeft !== 0 ? "Tell us what you think" : "Want more tries?"}
            </Link>
          </>
        )}
      </Grid>
    </Grid>
  );
};

export default ChatContainer;
