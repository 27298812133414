import React, {useEffect, useState} from 'react'
import {Grid, TextField} from '@mui/material'
import {Button} from "@aws-amplify/ui-react"
import {MdOutlineClose} from 'react-icons/md'
import {v4 as uuid} from 'uuid';
import {useGridApiContext} from "@mui/x-data-grid-pro";
import {debounce} from 'lodash'

export function LinkInputCellRow({link, removeLink, updateLink}) {
    const [name, setName] = useState(link.name);
    const [url, setURL] = useState(link.url);

    const debouncedUpdateLink = debounce(updateLink, 500);

    useEffect(() => {
        debouncedUpdateLink({ ...link, name, url });
    }, [debouncedUpdateLink, link, name, url])

    return (
        <Grid container item spacing={2} alignItems="center">
            <Grid item xs={12} sm={5}>
                <TextField
                    label="Title"
                    value={name}
                    onChange={e => setName(e.target.value)}
                    fullWidth
                />
            </Grid>
            <Grid item xs={12} sm={5}>
                <TextField
                    label="URL"
                    value={url}
                    onChange={e => setURL(e.target.value)}
                    fullWidth
                />
            </Grid>
            <Grid item xs={1}>
                <Button onClick={() => removeLink(link.id)}><MdOutlineClose /></Button>
            </Grid>
        </Grid>
    )
}

function LinkInputCell({id, value, field, onValueChange}) {
    const apiRef = useGridApiContext();

    // Generate IDs for each link so we can remove and update the correct link
    const [links, setLinks] = useState(() =>
        value ? value.map((link) => ({ ...link, id: uuid() })) : []
    )

    useEffect(() => {
        onValueChange(links, true)
        apiRef.current.setEditCellValue({ id, field, value: links.map(link => ({name: link.name, url: link.url})) });
    }, [apiRef, field, id, links, onValueChange])

    const addNewLink = () => {
        setLinks([...links, {id: uuid(), name: '', url: ''}])
    }

    const removeLink = (id) => {
        const newLinks = links.filter((link) => link.id !== id)
        setLinks(newLinks)
    }

    const updateLink = (updatedLink) => {
        setLinks(prevLinks => (
            prevLinks.map(link =>
                link.id === updatedLink.id ? updatedLink : link
            )
        ))
    }

    return (
        <form style={{width: '100%', padding: '1rem'}}>
            <Grid container spacing={2} alignItems={'center'}>
                {
                    links.map(link =>
                        <LinkInputCellRow
                            key={link.id}
                            link={link}
                            removeLink={removeLink}
                            updateLink={updateLink}
                        />
                    )
                }
            </Grid>

            <Button style={{marginTop: '1rem'}} onClick={addNewLink}>
                Add new link
            </Button>
        </form>
    );
}

export default LinkInputCell;