import React, { useEffect, useState } from 'react';
import { Auth } from 'aws-amplify';

interface AuthenticatedRouteProps {
    component: React.ReactNode;
    fallbackComponent: React.ReactNode;
}

const AuthenticatedRoute: React.FC<AuthenticatedRouteProps> = ({ component, fallbackComponent }) => {
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const [isAuthenticating, setIsAuthenticating] = useState(true);

    useEffect(() => {
        checkAuthState();
    }, []);

    const checkAuthState = async () => {
        try {
            await Auth.currentAuthenticatedUser();
            setIsAuthenticated(true);
        } catch {
            setIsAuthenticated(false);
        } finally {
            setIsAuthenticating(false);
        }
    };

    if (isAuthenticating) {
        return <div>Loading...</div>; // Or any other loading indicator
    }

    return isAuthenticated ? <>{component}</> : <>{fallbackComponent}</>;
};

export default AuthenticatedRoute;
