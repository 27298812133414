import { useState, useRef, useEffect } from "react";
import ChatContainer from "./ChatContainer";
import "./chat.css";

import config from "./config.json";
import DragResizeDialog from "./DragResizeDialog";
import useStudyBuddyState from "../../hooks/useStudyBuddyState";
import { Flex, Heading } from "@aws-amplify/ui-react";
import { useLocation } from "react-router-dom";
import AvatarAnimation from "./AvatarAnimation";

const STUDY_BUDDY_RESIZE_RATIO = 150;

const Buddy = ({ defaultContext = null, displayThumbnail = true }) => {
  const videoRef = useRef();
  const location = useLocation();
  const [context, setContext] = useState(defaultContext);
  const { user, selectedBuddy, setSelectedBuddy } = useStudyBuddyState();
  const [isAnimating, setIsAnimating] = useState(false);
  const [isInactive, setIsInactive] = useState(false);
  const [isOveractive, setIsOveractive] = useState(false);
  const [minimized, setMinimized] = useState(false);
  const [messages, setMessages] = useState([]);

  const [open, setOpen] = useState(false);

  useEffect(() => {
    let inactivityTimer = null;
    let componentTimer = null;

    const resetTimer = () => {
      clearTimeout(inactivityTimer);
      clearTimeout(componentTimer);

      inactivityTimer = setTimeout(() => {
        setIsInactive(true);
      }, config.inactivity_timer || 120000); // 2 minutes in milliseconds

      componentTimer = setTimeout(() => {
        setIsOveractive(true);
      }, config.overactivity_timer || 1200000); // 20 minutes in milliseconds
    };
    resetTimer();

    document.addEventListener("mousemove", resetTimer);
    document.addEventListener("keydown", resetTimer);

    return () => {
      document.removeEventListener("mousemove", resetTimer);
      document.removeEventListener("keydown", resetTimer);

      clearTimeout(inactivityTimer);
      clearTimeout(componentTimer);
    };
  }, []);

  // On selectedBuddy changed
  useEffect(() => {
    if (!selectedBuddy) return;

    import(`./data/${selectedBuddy}.json`)
      .then((module) => {
        setContext(module.default);
      })
      .catch((error) => {
        console.error("Error loading module:", error);
      });
  }, [selectedBuddy]);

  //auto close chat box when route changes
  useEffect(() => {
    setMessages([]);
  }, [location.pathname]);

  const handleCloseDialog = () => {
    if (!minimized) setOpen(false);
  };

  const handleVideoButtonClick = () => {
    videoRef.current?.play();
    setIsAnimating(true);
    setOpen(true);
    setTimeout(
      () => {
        setIsAnimating(false);
      },
      config.ui.video_thumbnail.animated
        ? config.ui.video_thumbnail.animation_duration
        : 0
    ); // This timeout should match the duration of your CSS transition
  };

  const getPingMessage = () => {
    if (!selectedBuddy || !context) return "Hello!";

    const messages = isInactive ? "inactivity" : "overactivity";

    const randomIndex = Math.floor(
      Math.random() * context.technical.ping[messages].length
    );
    return context.technical.ping[messages][randomIndex];
  };

  const getRandomIdleAnimation = () => {
    const randomIndex = Math.floor(
      Math.random() * context.avatar.idle_animations.length
    );
    return context.avatar.idle_animations[randomIndex];
  };

  if (!config.enabled || !user) return <></>;

  // Not authorized on the page the user is currently viewing
  if (
    !config.authorized_routes.some((route) => location.pathname.includes(route))
  )
    return <></>;

  return (
    <>
      <DragResizeDialog
        nearFullscreen={config.ui.chat_box.fullscreen}
        open={open}
        onClose={handleCloseDialog}
        minimized={minimized}
      >
        <ChatContainer
          config={config}
          defaultContext={context}
          videoRef={videoRef}
          selectedBuddy={selectedBuddy}
          setSelectedBuddy={setSelectedBuddy}
          minimized={minimized}
          setMinimized={setMinimized}
          handleCloseDialog={handleCloseDialog}
          setMessages={setMessages}
          messages={messages}
        />
      </DragResizeDialog>
      <div className="Chat">
        {(isInactive || isOveractive) && !open && (
          <Flex
            id={"talk-video-preview-notifier"}
            justifyContent={"center"}
            alignItems={"center"}
            style={{
              width: config.avatar.thumbnail_size,
            }}
          >
            <Heading level={3}>{getPingMessage()}</Heading>
          </Flex>
        )}
        <div
          id="talk-video-preview-container"
          className={isAnimating ? "fade-out" : ""}
          onClick={handleVideoButtonClick}
          style={{ display: !isAnimating && open ? "none" : "block" }}
        >
          <div
            className="inner-container"
            style={{
              width: config.avatar.thumbnail_size,
              height: config.avatar.thumbnail_size,
            }}
          >
            <div className="bubble">Let's chat!</div>
            {selectedBuddy && (
              <AvatarAnimation
                avatarId={selectedBuddy}
                animationName={getRandomIdleAnimation()}
                scale={context.avatar.sprite.width / STUDY_BUDDY_RESIZE_RATIO }
              />
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default Buddy;
