import {Heading, Text, useAuthenticator, View} from "@aws-amplify/ui-react";
import {DataStore} from "aws-amplify";
import axios from "axios";
import {useEffect} from "react";
import {useNavigate} from "react-router-dom";
import {UserSubscription} from "../models";

export default function SubscriptionConfirmationScreen() {
    useEffect(() => {
        document.title = "Subscription Confirmation - TeachMe TV";
    }, []);

    const navigate = useNavigate();
    const {user} = useAuthenticator((context) => [context.user]);

    useEffect(() => {
        if (user) {
            const searchParams = new URLSearchParams(window.location.search);
            const stripeSessionID = searchParams.get('stripeSessionID');

            if (stripeSessionID) {
                const endpoint = `${process.env['REACT_APP_STRIPE_API_ENDPOINT']}session/${stripeSessionID}`;
                try {
                    axios.get(endpoint).then((promise) => {
                        const response = promise.data;
                        const newUserSubscription = new UserSubscription({
                            username: user.username,
                            stripeCustomerID: response.customer,
                            stripeSubscriptionID: response.subscription,
                        });
                        DataStore.query(UserSubscription).then((userSubscriptions) => {
                        if (userSubscriptions.length === 0)
                            DataStore.save(newUserSubscription);
                        });
                        navigate('/account');
                    });

                } catch (error) {
                    console.error(error);
                    alert("An error occurred while communicating with our servers. Please contact us at info@teachmetv.co and we'll resolve the issue on our end. Apologies for the inconvenience.");
                }
            }
        }
    }, [navigate, user]);

    return (
        <View>
            <Heading level={1}>Success!</Heading>
            <Text>You've successfully subscribed to TeachMe TV!</Text>
            <Text>You'll be redirected in a few seconds.</Text>
            <Text>Thank you for your support!</Text>
        </View>
    );
}