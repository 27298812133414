import React, {useCallback, useEffect, useState} from 'react';
import * as TranscribeClient from "../helpers/TranscribeClient";
import styled from "styled-components";
import {View} from "@aws-amplify/ui-react";

const Transcript = styled(View)`
  max-width: 500px;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  display: inline-flex;
  padding: 1rem;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  background: #FFF;
  box-shadow: 0 4px 16px 0 rgba(0, 0, 0, 0.25);
  margin-bottom: 1.5rem;
  z-index: 20;
  font-weight: bold;
`

function AudioRecorder({callbackFn, sampleRate}) {
    const [transcript, setTranscript] = useState('')

    const startRecording = useCallback(async () => {
        clearTranscription();
        const onTranscriptionDataReceived = (data) => {
            setTranscript((prevTranscript) => formatTranscript(prevTranscript + ' ' + data));
        }
        try {
            await TranscribeClient.startRecording('en-US', onTranscriptionDataReceived, sampleRate);
        } catch (error) {
            alert("An error occurred while recording: " + error.message);
            stopRecording();
        }
    }, [sampleRate])

    const stopRecording = function () {
        TranscribeClient.stopRecording();
    }

    useEffect(() => {
        startRecording()
        return () => stopRecording()
    }, [startRecording])

    const clearTranscription = () => {
        setTranscript('')
    };

    const formatTranscript = (str) => {
        // This regex will match any space character followed by any of the punctuations: . , ?
        return str.replace(/\s([.,?])/g, '$1');
    }

    useEffect(() => {
        const timer = setTimeout(() => {
            callbackFn(formatTranscript(transcript.toLowerCase()));
        }, 1000);
        return () => clearTimeout(timer);
    }, [transcript, callbackFn]);


    return transcript !== '' && (
        <Transcript>
            {formatTranscript(transcript)}
        </Transcript>
    );
}

export default AudioRecorder;
