import React, {useRef, useState} from 'react';
import {Button, View} from "@aws-amplify/ui-react";
import styled from "styled-components";
import AvatarAnimation from "../Buddy/AvatarAnimation";

const NameInputContainer = styled(View)`
  position: absolute;
  display: flex;
  justify-content: center;
  max-width: 500px;
  left: 20%;
  top: 20%;
  align-items: flex-end;
  z-index: 10;
  
  @media only screen and (max-width: 768px) {
    left: 1rem;
  }
`

const InputField = styled(View)`
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  @media only screen and (max-width: 768px) {
    & > label {
      background-color: white;
    }
  }
`

function NameInputScene({name, setName, selectedChar, video, isWithBackButton, onBackClick}) {
    const [_name, _setName] = useState(name);
    const audioRef = useRef(null);
    const [animationName, setAnimationName] = useState('talking');

    const handleAudioEnded = () => setAnimationName('idle_simple');

    const onChangeName = (e) => {
        _setName(e.target.value)
    }

    const onNameSubmit = () => {
        setName(_name)
    }

    return (
        <>
            <audio ref={audioRef} src={video} onEnded={handleAudioEnded} autoPlay={true} />
            <div style={{height: '100%', position: 'relative'}}>
                <NameInputContainer>
                    <InputField>
                        <label style={{fontWeight: 'bold'}}>Type in your name</label>
                        <input value={_name} onChange={onChangeName} />
                    </InputField>
                    <Button onClick={onNameSubmit} style={{backgroundColor: 'white'}}>Submit</Button>
                </NameInputContainer>

                <div style={{zIndex: 0, position: 'absolute', width: '100%', height: '100%'}}>
                    <AvatarAnimation
                        avatarId={selectedChar}
                        animationName={animationName} />
                </div>
            </div>
        </>
    );
}

export default NameInputScene;