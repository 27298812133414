import {useState, useEffect} from 'react';
import {getRecommendations} from "../helpers/getRecommendations";
import {NUM_RECOMMENDATIONS} from "../constants";

const useRecommendations = (user, grade, emotion, isOnboarding=false) => {
    const [recommendations, setRecommendations] = useState([]);

    useEffect(() => {
        if (grade !== '' && emotion !== '' && emotion !== 'ok' && recommendations.length === 0) {
            getRecommendations(user, grade, emotion, NUM_RECOMMENDATIONS,[],[], isOnboarding)
                .then(rec => setRecommendations(rec))
        }
    }, [grade, emotion, user, recommendations.length, isOnboarding]);

    return {
        recommendations, setRecommendations
    };
};

export default useRecommendations;
