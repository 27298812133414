export const GRADE_HEADINGS = {
    'K': 'Kindergarten',
    '1': '1st Grade',
    '2': '2nd Grade',
    '3': '3rd Grade',
    '4': '4th Grade',
    '5': '5th & 6th Grade',
}

export const EMOTIONS = {
    ok: {
        emoji: '😊',
        text: 'Calm, Happy, OK'
    },
    tired: {
        emoji: '😞',
        text: 'Tired, Sad, Sick, Bored'
    },
    silly: {
        emoji: '😜',
        text: 'Silly, Hyper, Confused'
    },
    mad: {
        emoji: '😠',
        text: 'Mad, Frustrated'
    }
}

export const CONTINUE_ACTIVITIES = {
    yes: {
        emoji: '😊️',
        text: 'Yes'
    },
    no: {
        emoji: '😜',
        text: 'No'
    }
}

export const FEEDBACK = {
    fun: {
        emoji: '😆',
        text: 'Fun'
    },
    ok: {
        emoji: '🙂',
        text: 'OK'
    },
    tiring: {
        emoji: '😞',
        text: 'Tiring'
    }
}

export const ACTIVITY_STATUS = {
    started: 'started',
    viewed: 'viewed',
    completed: 'completed'
}

export const NUM_RECOMMENDATIONS = 3

export const StepType = {
    CharacterSelection: "characterSelection",
    NameInput: "nameInput",
    Dialogue: "dialogue",
    GradeSelection: "gradeSelection",
    EmotionSelection: 'emotionSelection',
    FeedbackSelection: 'feedbackSelection',
    Conversation: "conversation",
    Rest: "rest",
    Recommendation: "recommendation",
    RecommendationConfirmation: 'recommendationConfirmation',
    ParentTutorial: 'parentTutorial',
    CheckStartActivities: 'checkStartActivities'
}

export const EmotionStepType = {
    ok: 0,
    tired: 1,
    silly: 2,
    mad: 3
}