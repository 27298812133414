import {
    Authenticator,
    Heading,
    Link,
    Text,
    useAuthenticator,
    useTheme,
    View
} from "@aws-amplify/ui-react";
import {useNavigate, useSearchParams} from 'react-router-dom';
import {useEffect, useState} from 'react';
import "react-datepicker/dist/react-datepicker.css";

export default function AccountScreen() {
    const navigate = useNavigate();

    useEffect(() => {
        document.title = "Account - TeachMe TV";
    }, []);

    const {tokens} = useTheme();
    const [searchParams] = useSearchParams();

    const {user, route} = useAuthenticator((context) => [context.user]);
    const [previousState] = useState(route)

    useEffect(() => {
        if (user && (route === 'authenticated' || route === 'signIn')) navigate('/home')
    }, [user, route, previousState, navigate])

    const isMobile = window.innerWidth < 600;

    const css = `
        body {
            background: rgb(102,206,245);
            background: linear-gradient(309deg, rgba(102,206,245,1) 0%, rgba(179,231,250,1) 100%);
        }
    `

    return (
        <>
            <style>{css}</style>
            <View
                width={isMobile ? '100%' : '700px'}
                margin={'auto auto'}
                backgroundColor={'#FAFAFA'}
                boxShadow={"0px 20px 0px -10px #FAFAFA, 0px -20px 0px -10px rgba(255, 255, 255, 255), 20px 0px 0px -10px #FAFAFA, -20px 0px 0px -10px #FAFAFA, 0px 0px 0px 10px #FF0000, 5px 5px 15px 5px rgba(0,0,0,0)"}
                padding={tokens.space.large}
            >
                <Heading level={1} paddingBottom={tokens.space.large}>Account</Heading>
                {
                    searchParams.get('email') &&
                    <View paddingBottom={tokens.space.medium}>
                        <Text>Verify your emails for your temporary password.</Text>
                    </View>
                }
                {
                    searchParams.get('subscriptionID') &&
                    <View paddingBottom={tokens.space.medium}>
                        <Text>Your email address is already in use. Please use another one to create your account or
                            contact
                            us <Link href='mailto:support@teachmetv.co'>by email</Link>.</Text>
                    </View>
                }

                <Authenticator
                    initialState={'signIn'}
                    hideSignUp
                />
            </View>

        </>
    )
}