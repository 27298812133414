import {Button, Flex, Heading, Image, Text, useTheme, View} from "@aws-amplify/ui-react";
import PropTypes from 'prop-types';
import styled from 'styled-components';
import {useContext} from "react";
import {AudioContext} from "./App";
import {GrClose} from "react-icons/gr";
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme as useMuiTheme } from '@mui/material/styles';

export function MainSection({children}) {
    const {tokens} = useTheme();

    const isMobile = window.innerWidth < 769;

    return (<Flex
        direction={{base: 'column'}}
        justifyContent='center'
        alignItems='flex-start'
        paddingTop={isMobile ? 0 : tokens.space.xxl}
        paddingBottom={isMobile ? 0 : tokens.space.xxl}
    >
        {children}
    </Flex>)
}

MainSection.propTypes = {
    children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.element), PropTypes.element.isRequired])
}

export function PrimaryButton(props) {
    return (<Button
        variation="primary"
        fontSize={32}
        textAlign={'center'}
        fontWeight={"bold"}
        borderRadius={300}
        boxShadow="5px 5px 5px 5px rgba(0,0,0,.25)"
        padding={'10px 40px 10px 40px'}
        {...props}
    >
        {props.text}
    </Button>)
}

export function LandingButton(props) {
    const {text, colors, plansRef, ...rest} = props;

    const theme = useMuiTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    return (<Button
        position={'relative'}
        backgroundColor={colors && colors.background ? colors.background : '#ffc022'}
        color={colors && colors.font ? colors.font : 'black'}
        borderRadius={30}
        border={'thick solid black'}
        fontSize={isMobile ? '1rem' : '1.6rem'} // Smaller font size on mobile
        width={isMobile ? '160px' : '250px'} // Smaller width on mobile
        style={{zIndex: 1}}
        onClick={() => plansRef.current.scrollIntoView({behavior: "smooth", block: "start", inline: "nearest"})}
        {...rest}
    >
        {text}
    </Button>)
}

export function SubscribeButton(props) {
    const searchParams = new URLSearchParams(window.location.search);
    const target = searchParams.get('target');

    const { pricingDict, ...rest } = props;

    if (!target) return (<>
        <PrimaryButton
            text="Subscribe as parent"
            onClick={() => window.location.href = process.env['REACT_APP_STRIPE_PAYMENT_LINK']}
            {...rest}
        />
        <PrimaryButton
            text="Subscribe as teacher"
            onClick={() => window.location.href = process.env['REACT_APP_STRIPE_PAYMENT_LINK_TEACHER']}
            {...rest}
        />
    </>); else return (<PrimaryButton
        text="Subscribe"
        onClick={() => window.location.href = process.env[pricingDict[target].paymentLink]}
        {...rest}
    />)
}

const BackButtonStyledImage = styled(Image)`
  width: 10vw;
  height: 10vw;
  max-width: 80px;
  max-height: 80px;
  cursor: pointer;

  &:hover {
    filter: hue-rotate(180deg);
  }
`

export function BackButton(props) {
    const {onClick} = props;
    return (<BackButtonStyledImage
        alt={'Back button'}
        src={'/images/kids-zone/back-button.svg'}
        onClick={onClick}
        {...props}
    />)
}

const AudioButtonStyledImage = styled(Image)`
  border: none;
  cursor: pointer;
  transition: transform 0.3s;

  &:hover {
    transform: scale(1.1);
  }
`

export function AudioButton(props) {
    const {onClick, image, sound} = props;
    const {children, ...propsWithoutChildren} = props;
    const {playVO} = useContext(AudioContext);

    return (<>
        <AudioButtonStyledImage
            alt={image}
            src={`/images/${image}`}
            onClick={onClick}
            onMouseEnter={() => playVO(sound)}
            {...propsWithoutChildren}
        />
        {children}
    </>)
}

export function AudioText(props) {
    const {sound, children} = props;
    const {playVO} = useContext(AudioContext);

    return (<Text
        onMouseEnter={() => playVO(sound)}
        {...props}
    >
        {children}
    </Text>)
}

export function FlexCenteredColumn(props) {
    const {children} = props
    return (<Flex
        direction={'column'}
        alignItems={'center'}
        justifyContent={'center'}
        position={'relative'}
        {...props}
    >
        {children}
    </Flex>)
}

export function FlexCentered(props) {
    const {children} = props
    return (<Flex
        wrap={'wrap'}
        alignItems={'center'}
        justifyContent={'center'}
    >
        {children}
    </Flex>)
}

export function MuliHeading(props) {
    const {level, children} = props
    return (<Heading level={level} fontFamily={'muli, sans-serif'} {...props}>
        {children}
    </Heading>)
}

export const Tooltip = ({
  continuous,
  index,
  size,
  step,
  backProps,
  closeProps,
  primaryProps,
  tooltipProps,
}) => (
  <View {...tooltipProps} backgroundColor={'white'} borderRadius={20} padding={'1rem 2rem'}>
      <Flex justifyContent="space-between" alignItems="baseline" gap={'2rem'}>
          <p style={{margin: 0}}>{step.content}</p>
          <Button {...closeProps} backgroundColor={'none'} border={'none'} padding={0}>
              <GrClose />
          </Button>
      </Flex>
      <View fontSize={"1rem"}>
          <View color={"rgba(0, 0, 0, 0.5)"} fontSize={"1rem"} display={"inline-block"}>{index + 1} / {size}</View>
          {index + 1 === size && <>&nbsp;&#129395;</>}
      </View>
  </View>
);

export const SectionHeading = ({children}) => (
    <View
        backgroundImage={"url('/images/kids-zone/brush-stroke.svg')"}
        style={{
            backgroundRepeat: 'no-repeat', backgroundSize: 'contain', backgroundPosition: 'center'
        }}
        height={'8vw'}
        position={'relative'}
        display={'flex'}
        justifyContent={'center'}
        alignItems={'center'}
    >
        <Heading
            level={1}
            fontFamily={'muli, sans-serif'}
            fontSize={'2.9vw'}
        >
            {children}
        </Heading>
    </View>
)